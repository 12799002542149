// import React, { useEffect, useState } from 'react';
// import { Button, Modal, Form } from 'react-bootstrap';
// import { urlBase } from '../../../utils/API';
// import { createLesson } from '../../../utils/lessons';
// // import { BoxLoading } from 'react-loadingg';
// import axios from 'axios';
// import { FileUploader } from "../../../utils/uploadFile";
// // import { S3Client, PutObjectCommand } from '@aws-sdk/client-s3';
// import { useSelector } from 'react-redux';
// import AddLessonOther from './AddLessonOther';
// // import S3FileUpload from 'react-s3';
// // import { uploadFile } from 'react-s3';

// function AddLesson({ materialCode }) {


//   // ------------
//   const [loading, setLoading] = useState(false);
//   const [showModal, setShowModal] = useState(false);
//   const [lessonName, setLessonName] = useState('');

//   const [image, setImage] = useState(null);
//   const [video, setVideo] = useState(null);
//   const [videoLink, setVideoLink] = useState(null);
//   const [pdf, setPdf] = useState(null);

//   const [imageUrl, setImageUrl] = useState();
//   const [message, setMessage] = useState('');
//   const [progress, setProgress] = useState(0); // State to handle upload progress


//   // const {user} = useSelector(state => state.loggedUser);
//   const handleOpenModal = () => {
//     setShowModal(true);
//   };

//   const handleCloseModal = () => {
//     setShowModal(false);
//   };

//   const handleLessonNameChange = (e) => {
//     setLessonName(e.target.value);
//   };
//   //-------

//   //-------
//   // const s3 = new S3Client({
//   //   region: 'me-central-1',
//   //   credentials: {
//   //     accessKeyId: 'AKIA5LSNJPKWPMFJUFTN',
//   //     secretAccessKey: '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'
//   //   }
//   // });

//   //
//   const { user } = useSelector(({ loggedUser }) => {
//     return { user: loggedUser.user }
//   })
//   const handleFileChange = (type) => {
//     switch (type) {
//       case 'image':
//         setMessage('Uploading image...');
//         break;
//       case 'video':
//         setMessage('Uploading video...');
//         break;
//       case 'pdf':
//         setMessage('Uploading PDF...');
//         break;
//       default:
//         setMessage('Uploading...');
//     }

//     // You may want to handle the file upload process here
//     // For now, let's assume the file upload happens instantly
//   };

//   //-------------
//   //                                 LOCAL UPLOAD ENDPOINT
//   const uploadingFileLocally = (data) => {
//     const formData = new FormData();
//     formData.append("file", data);
//     formData.append("user", user?._id)
//     return axios({
//       method: "POST",
//       url: `${urlBase}/api/upload-file-locally?userId=${user?._id}`,
//       data: formData,
//       onUploadProgress: (progressEvent) => {
//         const percentage = Math.round(
//           (progressEvent.loaded * 100) / progressEvent.total
//         );
//         setProgress(percentage)

//       }
//     })
//   }

//   //-------------



//   const handleSaveChanges = async () => {
//     try {
//       setLoading(true);

//       let content = [];

//       if (image) {
//         // await AWSUpload(image)
//         console.log("image")
//         handleFileChange(image.type)
//         const res = await uploadingFileLocally(image)

//         // await uploadReact_S3(image);
//         content.push({ ...res?.data?.data, type: image?.type });

//       }
//       if (video) {
//         console.log("video")
//         handleFileChange(video.type)
//         const res = await uploadingFileLocally(video)

//         content.push({ ...res?.data?.data, type: video?.type });

//       }
//       if (videoLink) {
//         console.log("link")
//         // handleFileChange(video.type)
//         const res = await uploadingFileLocally(videoLink)

//         console.log(res)

//         content.push({ ...res?.data?.data, type: "link", name: videoLink, source: "external" });

//       }
//       if (pdf) {
//         console.log("pdf")
//         handleFileChange(pdf.type)
//         const res = await uploadingFileLocally(pdf)

//         content.push({ ...res?.data?.data, type: pdf?.type });

//       }

//       const lessonData = {
//         name: lessonName,
//         materialCode,
//         content,
//       };

//       const response = await createLesson(lessonData);
//       console.log(response)
//       setMessage(response.data.message);
//       setLoading(false);

//       handleCloseModal();
//       alert(response.data.message);
//     } catch (error) {
//       console.error('Error:', error);
//       setMessage(error.message);
//       setLoading(false);
//     }
//   };



//   return (
//     <div>
//       <button className='btn btn buttonsGroub' onClick={handleOpenModal}>
//         <span className='font'> إضافة درس</span>
//       </button>

//       <Modal show={showModal} onHide={handleCloseModal} className='font'>
//         <Modal.Header closeButton>
//           <Modal.Title>اضافة درس جديد</Modal.Title>
//           <div>{message}</div>
//         </Modal.Header>
//         <Modal.Body>
//           <div dir={"ltr"}>{progress}% uploaded / {message}</div>
//           {!loading ? (
//             <>
//               <Form.Group controlId="lessonName">
//                 <Form.Label>اسم الدرس</Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="ادخل اسم الدرس"
//                   value={lessonName}
//                   onChange={handleLessonNameChange}
//                   multiple={false}
//                 />
//               </Form.Group>

//               <Form.Group controlId="lessonFile">
//                 <Form.Label>رفع صورة</Form.Label>
//                 <Form.Control
//                   type="file"
//                   onChange={(event) => setImage(event.target.files[0])}
//                 />
//               </Form.Group>
//               {user?.role === "Admin" ?

//                 <Form.Group controlId="lessonFile">
//                   <Form.Label>رفع الفيديو</Form.Label>
//                   <Form.Control
//                     type="file"
//                     onChange={(event) => setVideo(event.target.files[0])}
//                   />
//                 </Form.Group>
//                 : <></>}
//               <Form.Group controlId="lessonFile">
//                 <Form.Label>اضف رابط الفيديو</Form.Label>
//                 <Form.Control
//                   type="text"
//                   onChange={(event) => setVideoLink(event.target.value)}
//                 />
//               </Form.Group>

//               <Form.Group controlId="lessonFile">
//                 <Form.Label>رفع pdf</Form.Label>
//                 <Form.Control
//                   type="file"
//                   onChange={(event) => setPdf(event.target.files[0])}
//                 />
//               </Form.Group>

//               <Button variant="primary" onClick={handleSaveChanges}>
//                 اضافة الدرس
//               </Button>
//             </>
//           ) : (
//             <div className="loaderIcon">
//               <span className="loader"></span>
//             </div>
//           )}
//         </Modal.Body>
//         <Modal.Footer>
//           <Button variant="secondary" onClick={handleCloseModal}>
//             Close
//           </Button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// }

// export default AddLesson;


import React, { useState } from 'react';
import { Button, Modal, Form, Dropdown } from 'react-bootstrap';
import { urlBase } from '../../../utils/API';
import { createLesson } from '../../../utils/lessons';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Media from '../../Admin/media/Media';
import { FileUploader } from "../../../utils/uploadFile";

function AddLesson({ materialCode }) {
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [lessonName, setLessonName] = useState('');
  const [image, setImage] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoLink, setVideoLink] = useState(null);
  const [pdf, setPdf] = useState(null);
  const [imageUrl, setImageUrl] = useState();
  const [message, setMessage] = useState('');
  const [progress, setProgress] = useState(0);
  const [showOtherContent, setShowOtherContent] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [files, setFiles] = useState([null]);
  const [showMediaLibrary, setShowMediaLibrary] = useState(false);
  const [selectedMediaIds, setSelectedMediaIds] = useState([]);

  const { user } = useSelector(({ loggedUser }) => {
    return { user: loggedUser.user }
  });

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleLessonNameChange = (e) => setLessonName(e.target.value);

  const handleTypeSelect = (type) => {
    setSelectedType(type);
  };

  const addFileInput = () => {
    setFiles([...files, null]);
  };

  const handleMediaSelect = (selectedIds) => {
    setSelectedMediaIds(selectedIds);
    setShowMediaLibrary(false);
  };

  const handleFileChange = (index, file) => {
    const newFiles = [...files];
    newFiles[index] = file;
    setFiles(newFiles);
  };

  const uploadingFileLocally = (data) => {
    const formData = new FormData();
    formData.append("file", data);
    formData.append("user", user?._id)
    return axios({
      method: "POST",
      url: `${urlBase}/api/upload-file-locally?userId=${user?._id}`,
      data: formData,
      onUploadProgress: (progressEvent) => {
        const percentage = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentage)
      }
    })
  };

  const renderInputBasedOnType = () => {
    switch (selectedType) {
      case 'upload':
        return (
          <div>
            {files.map((file, index) => (
              <div key={index} className="d-flex align-items-center mt-3">
                <input
                  style={{ width: "100%" }}
                  type="file"
                  className="form-control"
                  onChange={(e) => handleFileChange(index, e.target.files[0])}
                />
                {index === files.length - 1 && (
                  <button className="btn btn-primary ms-2" onClick={addFileInput}>+</button>
                )}
              </div>
            ))}
          </div>
        );
      case 'link':
        return <input type="text" className="form-control mt-3" placeholder="Enter URL" />;
      case 'media':
        return (
          <Button variant="primary" className="mt-3 w-100" onClick={() => setShowMediaLibrary(true)}>
            Open Media Library
          </Button>
        );
      default:
        return null;
    }
  };

  const handleSaveChanges = async () => {
    try {
      setLoading(true);
      let content = [];

      if (!showOtherContent) {
        if (image) {
          const res = await uploadingFileLocally(image);
          content.push({ ...res?.data?.data, type: image?.type });
        }
        if (video) {
          const res = await uploadingFileLocally(video);
          content.push({ ...res?.data?.data, type: video?.type });
        }
        if (videoLink) {
          const res = await uploadingFileLocally(videoLink);
          content.push({ ...res?.data?.data, type: "link", name: videoLink, source: "external" });
        }
        if (pdf) {
          const res = await uploadingFileLocally(pdf);
          content.push({ ...res?.data?.data, type: pdf?.type });
        }
      } else {
        if (selectedType === 'upload') {
          for (const file of files) {
            if (file) {
              const uploader = new FileUploader(user?._id, file);
              try {
                const uploadResponse = await uploader.uploadFile('AWS-S3');
                if (uploadResponse?.data?.media?._id) {
                  content.push(uploadResponse.data.media._id);
                }
              } catch (error) {
                console.error('File upload failed:', error);
                return;
              }
            }
          }
        }
        if (selectedMediaIds.length > 0) {
          content = [...content, ...selectedMediaIds];
        }
      }

      const lessonData = {
        name: lessonName,
        materialCode,
        content,
      };

      const response = await createLesson(lessonData);
      setMessage(response.data.message);
      handleCloseModal();
      alert(response.data.message);
    } catch (error) {
      console.error('Error:', error);
      setMessage(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <button className='btn btn buttonsGroub' onClick={handleOpenModal}>
        <span className='font'> إضافة درس</span>
      </button>

      <Modal show={showModal} onHide={handleCloseModal} className='font'>
        <Modal.Header closeButton>
          <Modal.Title>اضافة درس جديد</Modal.Title>
          <div>{message}</div>
        </Modal.Header>
        <Modal.Body>
          <div dir={"ltr"}>{progress}% uploaded / {message}</div>
          {!loading ? (
            <>
              <Form.Group controlId="lessonName">
                <Form.Label>اسم الدرس</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="ادخل اسم الدرس"
                  value={lessonName}
                  onChange={handleLessonNameChange}
                  multiple={false}
                />
              </Form.Group>

              <Form.Group className="mb-3 mt-3">
                <Form.Check
                  type="checkbox"
                  label="إضافة محتوى آخر"
                  onChange={(e) => setShowOtherContent(e.target.checked)}
                />
              </Form.Group>

              {!showOtherContent ? (
                <>
                  <Form.Group controlId="lessonFile">
                    <Form.Label>رفع صورة</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(event) => setImage(event.target.files[0])}
                    />
                  </Form.Group>

                  {user?.role === "Admin" &&
                    <Form.Group controlId="lessonFile">
                      <Form.Label>رفع الفيديو</Form.Label>
                      <Form.Control
                        type="file"
                        onChange={(event) => setVideo(event.target.files[0])}
                      />
                    </Form.Group>
                  }

                  <Form.Group controlId="lessonFile">
                    <Form.Label>اضف رابط الفيديو</Form.Label>
                    <Form.Control
                      type="text"
                      onChange={(event) => setVideoLink(event.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="lessonFile">
                    <Form.Label>رفع pdf</Form.Label>
                    <Form.Control
                      type="file"
                      onChange={(event) => setPdf(event.target.files[0])}
                    />
                  </Form.Group>
                </>
              ) : (
                <div className="mt-3">
                  <Dropdown className='w-100' style={{ width: '100%' }}>
                    <Dropdown.Toggle variant="primary" id="dropdown-basic" style={{ width: '100%' }}>
                      Select Type
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleTypeSelect('media')}>From Media Library</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleTypeSelect('link')}>From Link</Dropdown.Item>
                      <Dropdown.Item onClick={() => handleTypeSelect('upload')}>Upload File</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>

                  {renderInputBasedOnType()}
                </div>
              )}

              <Button variant="primary" onClick={handleSaveChanges} className="mt-3">
                اضافة الدرس
              </Button>
            </>
          ) : (
            <div className="loaderIcon">
              <span className="loader"></span>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showMediaLibrary} onHide={() => setShowMediaLibrary(false)} centered size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Media Library</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Media onMediaSelect={handleMediaSelect} showForMedia={true} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AddLesson;
