import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Get_All_Withdraws } from '../../../actions/withdrawsAct';
import { Table } from 'react-bootstrap';
import AcceptDraw from './AcceptDraw';

const Withdraws = () => {
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);

    useEffect(() => {
        const getAllWihdraws = async () => {
            try {
                const res = await dispatch(Get_All_Withdraws(loggedUser?.user?._id));
                // console.log(res)
            } catch (e) {
                console.log(e)
            }
        }
        getAllWihdraws();
    }, [])
    const withdraws = useSelector((state) => state.withdrawRed);

    return (
        <div className="p-4">

            <Table striped bordered hover responsive className='font'>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Currency</th>
                        <th>Status</th>
                        <th>Details</th>
                        <th>User</th>
                        <th>Accept</th>
                        <th>Reject</th>
                        <th>Cancel</th>
                    </tr>
                </thead>
                <tbody>
                    {withdraws?.map((withdraw) => (
                        <tr key={withdraw?._id}>
                            <td>{new Date(withdraw?.date).toLocaleDateString()}</td>
                            <td>{withdraw?.amount}</td>
                            <td>{withdraw?.currency}</td>
                            <td>
                                <span className={`badge ${withdraw?.status === 'accepted' ? 'bg-success' :
                                        withdraw?.status === 'canceled' ? 'bg-danger' :
                                            withdraw?.status === 'processing' ? 'bg-warning' : 'bg-success'
                                    }`}>
                                    {withdraw?.status}
                                </span>
                            </td>
                            <td>{withdraw?.details}</td>
                            <td>{withdraw?.user?.name}</td>
                            <td>
                                <AcceptDraw withdraw={withdraw} type="accept" />
                            </td>
                            <td>
                                <AcceptDraw withdraw={withdraw} type="reject" />
                            </td>
                            <td>
                                <AcceptDraw withdraw={withdraw} type="cancel" />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default Withdraws
