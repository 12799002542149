import React from 'react'
import { Outlet } from 'react-router-dom';
import PageContainer from '../../components/layout/PagecContainer';
import { useSelector } from 'react-redux';

const ReservationSystemWrapper = () => {
  const { lang, dir } = useSelector(({ language }) => ({
    lang: language.lang,
    dir: language.dir,
}));
  return (
    <div className='reserveSystem' dir={dir === "rtl" ? "ltr" : "rtl"}>

      <Outlet />

    </div>
  )
}

export default ReservationSystemWrapper
