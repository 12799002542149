export const HomePage = {
    landing_title: {
        AR: 'منصة',
        EN: 'platform',
        FR: 'plate-forme'
    },
    transactions: {
        AR: "المعاملات",
        EN: "Transactions"
    },
    landing_title2: {
        AR: 'برديات',
        EN: 'Bardyat',
        FR: 'Bardyat'
    },
    landing_title3: {
        AR: 'للتعليم الإفتراضي',
        EN: 'virtual education',
        FR: "pour l'éducation virtuelle"
    },
    landing_desc: {
        AR: "منصة تعليمية مصرية مبنية على أسس ومعايير تربوية، تقدم جملة من الخدمات التعليمية والتربوية إلى كلا من المعلمين، والطلاب، وأولياء الأمور؛ بهدف تحسين عمليتي التعليم والتعلم. ",
        EN: "An Egyptian educational platform built on educational principles and standards, offering a range of educational and pedagogical services to teachers, students, and parents; with the aim of improving the processes of teaching and learning.",
        FR: "Une plateforme éducative égyptienne construite sur des principes et des normes pédagogiques, offrant une gamme de services éducatifs et pédagogiques aux enseignants, aux élèves et aux parents ; dans le but d'améliorer les processus d'enseignement et d'apprentissage."
    },
    landing_about: {
        AR: 'تعرف علي برديات',
        EN: 'Know About Bardyat',
        FR: 'connais sur Bardyat'
    },
    landing_start_now: {
        AR: 'أبدء الآن',
        EN: 'start now',
        FR: 'Commencez maintenant'
    },
    whatDefference_title: {
        AR: "ما المختلف في برديات ؟",
        EN: "What is different about Bardiyyat ?",
        FR: "Qu'est-ce qui est différent à propos de Bardiyyat ?"
    },
    whatDefference_store: {
        AR: "متجر للمنتجات",
        EN: "Store For Products",
        FR: "Magasin de produits"
    },
    whatDefference_Games: {
        AR: "ألعاب تعليمية",
        EN: "Educational games",
        FR: "Jeux éducatifs"
    },
    whatDefference_monetoring: {
        AR: "متابعة مباشرة",
        EN: "Parent Monetoring",
        FR: "Suivi direct pour le tuteur de l'élève"
    },
    whatDefference_interactiveLessons: {
        AR: 'دروس تفاعلية',
        EN: 'Interactive lessons',
        FR: "Cours interactifs"
    },
    about_us_title: {
        AR: "من نحن؟",
        EN: "Who are we ?",
        FR: "Qui sommes-nous ?"
    },
    about_us_desc: {
        AR: "هى منصة تعليمية مصرية تطمح الى الوصول لمكانة كبيرة ومعروفة على المستوى المحلى مبدئيا وتقديم خدمات قادرة على التنافس مع أكبر المنصات التعليمية المصرية .",
        EN: "It is an Egyptian educational platform that aspires to achieve a prominent and well-known position locally initially and to provide services capable of competing with the largest educational platforms in Egypt.",
        FR: "C'est une plateforme éducative égyptienne qui aspire à atteindre une position importante et reconnue localement dans un premier temps, et à fournir des services capables de rivaliser avec les plus grandes plateformes éducatives en Égypte."
    },
    tools_store_title: {
        AR: "متجر للأدوات",
        EN: "Store for tools",
        FR: "Magasin d'outils"
    },
    tools_store_desc: {
        AR: "متجرنا يوفر الأدوات المدرسية والكتب التي يمكنك طلبها أونلاين تصلك أينما كنت وبأسعار منافسة",
        EN: "Our store provides school supplies and books that you can order online and have them delivered to you wherever you are, at competitive prices.",
        FR: "Notre magasin propose des fournitures scolaires et des livres que vous pouvez commander en ligne et vous faire livrer où que vous soyez, à des prix compétitifs."
    },
    challenge_title: {
        AR: "أختبر وتحدى",
        EN: 'Test and challenge',
        FR: 'Testez et défiez'
    },
    challenge_section1: {
        AR: "أحصل على تحديات جديدة يومية مع زملائك وقم بمشاركتها ",
        EN: 'Get new daily challenges with your peers and share them',
        FR: 'Obtenez de nouveaux défis quotidiens avec vos pairs et partagez-les'
    },
    challenge_section2: {
        AR: "تعرف على أدائك من خلال أختبارات ومراجعات دورية",
        EN: "Know your performance through regular tests and reviews",
        FR: 'Connaissez votre performance à travers des tests et des évaluations régulières'
    },
    nav_1: {
        AR: "الصفحة الرئيسية",
        EN: "Homepage",
        FR: "Page d'accueil"
    },
    nav_2: {
        AR: "الصف الدراسي",
        EN: "Classroom",
        FR: "Classe"
    },
    nav_3: {
        AR: "المتجر",
        EN: "Store",
        FR: "Magasin"
    },
    nav_4: {
        AR: "المجتمع",
        EN: "Community",
        FR: "Communauté"
    },
    nav_5: {
        AR: "تواصل معنا",
        EN: "Contact",
        FR: "Contactez"
    },
    nav_6: {
        AR: "لوحة التحكم",
        EN: "Dashboard",
        FR: "Tableau de bord"
    },
    nav_7: {
        AR: "تسجيل الخروج",
        EN: "Logout",
        FR: "Déconnexion"
    },
    nav_8: {
        AR: "اشترك الان",
        EN: "Subscribe",
        FR: "Abonnez-vous"
    },
    nav_9: {
        AR: "سجل الان",
        EN: "Register",
        FR: "Inscrivez-vous"
    },
    footer_about_us: {
        AR: "بـــــرديــــــــات مبســــــطـاهـا",
        EN: "Our vision is to provide comfort and assistance in enhancing your knowledge and skills",
        FR: "Notre vision est de fournir du confort et de l'assistance pour améliorer vos connaissances et compétences."
    },
    quick_links: {
        AR: 'روابط سريعة',
        EN: 'Quick links',
        FR: 'Liens rapides'
    }
    ,
    footer_about_us_title: {
        AR: "عنا",
        EN: "About",
        FR: "À propos"
    },
    footer_help: {
        AR: "مساعدة",
        EN: "Help",
        FR: "Aide"
    },
    categories: {
        AR: "التصنيفات",
        EN: "Categories",
        FR: "Catégories"
    },
    results: {
        AR: "النتائج",
        EN: "Results",
        FR: "Résultats"
    },
    program_sub: {
        AR: "اشتراك البرنامج",
        EN: "Program subscription",
        FR: "Abonnement au programme"
    },
    program_name: {
        AR: "اسم البرنامج",
        EN: "Program name",
        FR: "Nom du programme"
    },
    program_cost: {
        AR: "تكلفة البرنامج",
        EN: "Program cost",
        FR: "Coût du programme"
    },
    program_supervision: {
        AR: "تكلفة الاشراف",
        EN: "Supervision cost",
        FR: "Coût de supervision"
    },
    select_supervisor: {
        AR: "اختيار مشرف",
        EN: "select  supervisor",
        FR: "Choisir un superviseur"
    },
    yes: {
        AR: "نعم",
        EN: "Yes",
        FR: "Oui"
    },
    no: {
        AR: "لا",
        EN: "No",
        FR: "Non"
    },
    follow_steps: {
        AR: "اتبع الخطوات التالية",
        EN: "Follow the following steps",
        FR: "Suivez les étapes suivantes"
    },
    payment_method: {
        AR: "برجاء اختيار وسيلة الدفع",
        EN: "Please choose a payment method",
        FR: "Veuillez choisir un mode de paiement"
    },
    digital_wallet: {
        AR: "برجاء اختيار وسيلة الدفع",
        EN: "Digital wallet",
        FR: "Portefeuille numérique"
    },
    pay_on: {
        AR: "برجاء دفع مبلغ",
        EN: "Please make the payment",
        FR: "Veuillez effectuer le paiement"
    },
    on_number: {
        AR: "علي رقم",
        EN: "on Number",
        FR: "Sur le numéro"
    },
    then_upload: {
        AR: "ثم قم برفع ايصال الدفع من هنا",
        EN: "Then upload the payment receipt",
        FR: "Ensuite, téléchargez le reçu de paiement"
    },
    cancel: {
        AR: "الغاء",
        EN: "Cancel",
        FR: "Annuler"
    },
    pay: {
        AR: "ادفع",
        EN: "Pay",
        FR: "Payer"
    },

    bardyat_store: {
        AR: "متجر برديات",
        EN: "Bardyat Store",
        FR: "Boutique Bardiyyat"
    },
    products_collection: {
        AR: "متجر برديات",
        EN: "Product collection",
        FR: "Collection de produits"
    },
    all_products: {
        AR: "جميع المنتجات",
        EN: "All products",
        FR: "Tous les produits"
    },
    price: {
        AR: "السعر",
        EN: "Price",
        FR: "Prix"
    },
    show_cart: {
        AR: "عرض السلة",
        EN: "View cart",
        FR: "Voir le panier"
    },
    total: {
        AR: "المجموع",
        EN: "Total",
        FR: "Total"
    },
    order_confirm: {
        AR: "اتمام الطلب",
        EN: "Complete order",
        FR: "Finaliser la commande"
    },
    inStore: {
        AR: " في المخزن",
        EN: "Available in stock",
        FR: "Disponible en stock"
    },
    outOfStore: {
        AR: " غير متوفر في المخزن",
        EN: "Out of stock",
        FR: "En rupture de stock"
    },
    available_colors: {
        AR: "الالوان المتاحة",
        EN: "Available colors",
        FR: "Couleurs disponibles"
    },

    add_to_cart: {
        AR: "اضافة الي السلة",
        EN: "Add to cart",
        FR: "Ajouter au panier"
    },

    desc: {
        AR: "الوصف ",
        EN: "Description",
        FR: "Description"
    },

    image: {
        AR: "صورة ",
        EN: "Image",
        FR: "Image"
    },

    product: {
        AR: "المنتج",
        EN: "product",
        FR: "produit"
    },

    quantity: {
        AR: "العدد",
        EN: "Quantity",
        FR: "Quantité"
    },

    cart_summary: {
        AR: "اجمالي سلة المشتريات",
        EN: "Total shopping cart",
        FR: "Total du panier d'achats"
    },

    shipping_cost: {
        AR: "تكلفة الشحن",
        EN: "Shipping cost",
        FR: "Frais de livraison"
    },

    total_price: {
        AR: "الاجمالي",
        EN: "Total price",
        FR: "Prix total"
    },

    order_address: {
        AR: "من فضلك أدخل العنوان لاتمام الطلب",
        EN: "Please enter the address to complete the order",
        FR: "Veuillez saisir l'adresse pour finaliser la commande"
    },
    dashboard_programs: {
        AR: "البرامج الدراسية",
        EN: "Educational programs",
        FR: "Programmes éducatifs"
    },
    dashboard_results: {
        AR: "النتائج",
        EN: "Results",
        FR: "Résultats"
    },
    dashboard_subscription_req: {
        AR: "طلبات الاشتراك",
        EN: "Subscription requests",
        FR: "Demandes d'abonnement"
    },
    chooseAllLevels: {
        AR: " كل المستويات",
        EN: "All levels"
    },
    ChooseLevels: {
        Ar: "اختر المستويات التى تقوم بالتدريس لها",
        EN: "Choose levels to teach in"
    }
    ,
    dashboard_subscriptions: {
        AR: "مشتريات المتجر",
        EN: "Subscriptions",
        FR: "Abonnements"
    },
    dashboard_society: {
        AR: "المجتمع",
        EN: "Community",
        FR: "Communauté"
    },
    dashboard_profile_info: {
        AR: "معلومات الصفحة الشخصية",
        EN: "Profile information",
        FR: "Informations du profil"
    },

    parent: {
        AR: "والد",
        EN: "parent of",
        FR: "parent of"
    },

    logout: {
        AR: "تسجيل الخروج",
        EN: "log out",
        FR: "Déconnexion"
    },

    pay_status: {
        AR: "حالة الدفع",
        EN: "Payment status",
        FR: "État du paiement"
    },
    notes: {
        AR: "ملاحظات",
        EN: "Notes",
        FR: "Remarques"
    },
    pending: {
        AR: "تحت الانتظار",
        EN: "Pending",
        FR: "En attente"
    },
    not_found: {
        AR: "لا يوجد ",
        EN: "Not Found",
        FR: "Aucun"
    },
    week_schedule: {
        AR: "الجدول الاسبوعي",
        EN: "Weekly schedule",
        FR: "Emploi du temps hebdomadaire"
    },
    category: {
        AR: "الفئة",
        EN: "Category",
        FR: "Catégorie"
    },

    order: {
        AR: "الطلب",
        EN: "Order",
        FR: "Commande"
    },
    color: {
        AR: "اللون",
        EN: "Color",
        FR: "Couleur"
    },

    date: {
        AR: "التاريخ",
        EN: "Date",
        FR: "Date"
    },
    status: {
        AR: "الحالة",
        EN: "Status",
        FR: "État"
    },
    address: {
        AR: "العنوان",
        EN: "Address",
        FR: "Adresse"
    },
    go_to_store: {
        AR: "الذهاب الي المتجر الان",
        EN: "Go to store now",
        FR: "Aller au magasin maintenant"
    },
    you_have_no_lessons: {
        AR: "لست مشترك في اي دروس",
        EN: "You are not enrolled in any classes",
        FR: "Vous n'êtes inscrit à aucun cours"
    },
    subscribe_now: {
        AR: "اشترك الان",
        EN: "Subscribe now",
        FR: "Inscrivez-vous maintenant"
    },
    continue_education: {
        AR: "متابعة الدراسة",
        EN: "Continuing education",
        FR: "Continuer les études"
    },
    subjects: {
        AR: "المواد",
        EN: "Subjects",
        FR: "Les matières"
    },
    choices: {
        AR: "اختيارات",
        EN: "Choices",
        FR: "Choix"
    },
    contact_supervisor: {
        AR: "تواصل مع المشرف",
        EN: "Contact supervisor",
        FR: "Communiquer superviseur"
    },
    Weekly_honor_roll: {
        AR: "لوحة شرف الاسبوع",
        EN: "Weekly honor roll",
        FR: "Tableau d'honneur de la semaine"
    },
    no_subjects_added_yet: {
        AR: "لم يتم اضافة فصل دراسي بعد",
        EN: "No academic term has been added yet",
        FR: "Aucune session n'a encore été ajoutée"
    },
    materialCode: {
        AR: "كود الماده",
        EN: "Material Code"
    },
    main_material: {
        AR: "الماده الرئيسيه",
        EN: "Main Material"
    },
    subject_name: {
        AR: "اسم المادة",
        EN: "Course name",
        FR: "Nom du cours"
    },
    course_desc: {
        AR: "وصف الكورس",
        EN: "Course Description",
        FR: "Nom du cours"
    },
    teacher_name: {
        AR: "اسم المعلم",
        EN: "Teacher name",
        FR: "Nom de l'enseignant"
    },
    meeting_room: {
        AR: "غرفة الاجتماعات",
        EN: "Meeting room",
        FR: "Salle de réunion"
    },
    no_session_link: {
        AR: "لم يتم اضافة رابط الحصة بعد",
        EN: "The session link has not been added yet",
        FR: "Le lien de la session n'a pas encore été ajouté"
    },
    contact_student: {
        AR: 'تواصل مع الطالب',
        EN: 'Contact Student',
        FR: "Communiquer avec l'étudiant"
    },
    contact_teacher: {
        AR: 'تواصل مع المعلم',
        EN: 'Contact Teacher',
        FR: "Contacter le professeur"
    },
    contact_parent: {
        AR: 'تواصل مع ولي الامر',
        EN: 'Contact Parent',
        FR: "Communiquer avec le Fr"
    },
    contact: {
        AR: 'تواصل  ',
        EN: 'Contact',
        FR: "Contacter"
    },
    who_bardiaat: {
        AR: ' من برديات ؟',
        EN: 'Who Bardiaat?',
        FR: 'Qui Bardiaat?'
    },
    who_bardiaat_content: {
        AR: `هى منصة تعليمية مصرية تطمح الى الوصول لمكانة كبيرة ومعروفة على
        المستوى المحلى مبدئيا وتقديم خدمات قادرة على التنافس مع أكبر المنصات
        التعليمية المصرية .`,
        EN: `It is an Egyptian educational platform that aspires to achieve a significant and well-known status initially on the local level, providing services capable of competing with the largest Egyptian educational platforms.`,
        FR: `C'est une plateforme éducative égyptienne qui aspire à atteindre une position importante et reconnue au niveau local dans un premier temps, en offrant des services capables de rivaliser avec les plus grandes plateformes éducatives égyptiennes.`
    },
    our_goals: {
        AR: 'أهدافنا',
        EN: 'Our Goals',
        FR: 'Nos Objectifs'
    },
    our_goals_content: {
        AR: `     إنشاء منصة وسيطة بين الطالب والمدرس وسيكون دور المنصة فيه هو النظام
        الإدارى وتوفير المزايا والإمكانيات اللازمة بين الطالب والمدرس .
`,
        EN: `To create an intermediary platform between students and teachers, where the platform's role will be administrative, providing the necessary benefits and capabilities for both students and teachers.`
        ,
        FR: `Créer une plateforme intermédiaire entre les élèves et les enseignants, où le rôle de la plateforme sera administratif, en fournissant les avantages et les capacités nécessaires pour les élèves et les enseignants.`
    },
    our_vesion: {
        AR: 'رؤيتنا',
        EN: 'Our Vesion',
        FR: 'Notre Vision'
    },
    our_vesion_content: {
        AR: `            تقديم تجربة تعليمية مبتكرة وممتعة مبنية على أسس ومعايير تربوية؛ تعزز من التفاعل والمشاركة الفعالة بين المعلمين والطلاب وأولياء الأمور، وتُمكِن الطلاب من تحقيق إمكاناتهم الكاملة.
        `,
        EN: `To provide an innovative and enjoyable educational experience based on educational principles and standards; enhancing interaction and effective participation among teachers, students, and parents, and enabling students to achieve their full potential.`,
        FR: `Offrir une expérience éducative innovante et agréable basée sur des principes et des normes pédagogiques; renforcer l'interaction et la participation efficace entre les enseignants, les élèves et les parents, et permettre aux élèves de réaliser pleinement leur potentiel.`
    },
    the_message: {
        AR: "رسالتنا",
        EN: 'Our Message',
        FR: 'Notre message'
    },
    the_message_content: {
        AR: `إعداد جيل واعٍ من الطلاب في شتى مراحل التعليم؛ يكون قادر على فهم احتياجاته، ويوظفها بشكل مثمر في تحقيق أهدافه، مما يعزز من سبل منافسة التعليم المصري على المستوى القومي والعالمي.
        `,
        EN: `To prepare an aware generation of students at various educational stages; capable of understanding their needs and utilizing them productively to achieve their goals, thereby enhancing the competitiveness of Egyptian education at the national and global levels.`,
        FR: `Préparer une génération consciente d'élèves à divers stades de l'éducation; capable de comprendre leurs besoins et de les utiliser de manière productive pour atteindre leurs objectifs, renforçant ainsi la compétitivité de l'éducation égyptienne aux niveaux national et mondial.`
    },
    program_description: {
        AR: 'وصف البرنامج',
        EN: 'Program Description',
        FR: 'Description du programme'
    },
    superVisor_name: {
        AR: 'المشرف',
        EN: 'Supervisor',
        FR: 'Superviseuse'
    },
    chatting: {
        AR: 'دردشة',
        EN: 'Chatting',
        FR: 'bavardage'
    },
    No_SuperVisor_Added: {
        AR: 'لم يتم اضافتك الى اى مشرفين بعد',
        EN: 'No Supervisors added for you yet.',
        FR: `Aucun superviseur ajouté pour vous pour l'instant.`
    },


    // Navbar
    Home: {
        AR: "الصفحه الرئيسيه",
        EN: "Home"
    },
    Subjects: {
        AR: "البرامج االرئيسيه",
        EN: "Programs"
    },
    Teachers: {
        AR: "المعلمون",
        EN: "Teachers"
    },
    Contact_us: {
        AR: "تواصل معنا",
        EN: "contact us"
    },
    society: {
        AR: "المجتمع",
        EN: "School Community"
    },
    login: {
        AR: "تسجيل الدخول",
        EN: "Log In"
    },
    register: {
        AR: "اشترك الان",
        EN: "Register Now"
    },
    log_out: {
        AR: "تسجيل الخروج",
        EN: "Log Out"
    },
    dashboard: {
        AR: "الصفحه الشخصيه",
        EN: "Profile",
    },

    teacherLogin: {
        AR: "انضم كمعلم",
        EN: "Join As Teacher"
    },
    teacherService: {
        AR: "خدمات المعلم",
        EN: "Services Teacher"
    },
    asubTeac: {
        AR: "اشترك في خدمه",
        EN: "Subscription Service"
    }
    ,
    myServices: {
        AR: "خدماتي",
        EN: "My Services"
    }
    ,
    teacherJoin: {
        AR: "انضم كمعلم",
        EN: "Join As Teacher"
    },

    // HomeHeader Component
    info_header: {
        AR: `OLP-حيث يلتقي التعليم بالإبداع`,
        EN: "Where Education Meets Innovation",
        FR: `Là où l'éducation rencontre l'innovation`
    },
    info_header2: {
        AR: `انضم إلى OLP، حيث يجتمع المعلمون المبدعون مع الطلاب المتحمسين في بيئة تعليمية متميزة. اكتشف محتويات تعليمية متجددة وتواصل مع خبراء يساهمون في تطوير مهاراتك وإثراء معرفتك.`,
        EN: `Welcome to OLP, the ultimate platform where educators bring their best content to life and students unlock their full potential. Join a community of passionate teachers and eager learners, and explore a world of knowledge tailored just for you.`,
        FR: `Bienvenue sur OLP, la plateforme ultime où les éducateurs donnent vie à leurs meilleurs contenus et où les étudiants révèlent leur plein potentiel. Rejoignez une communauté de professeurs passionnés et d'apprenants motivés, et explorez un monde de connaissances conçu pour vous.`
    },
    explore: {
        AR: "اكتشف المزيد",
        EN: "Learn More"
    },
    watch: {
        AR: "شاهد الفيديو",
        EN: "watch video"
    },
    // Our platform (HomeHeader)
    titlePlatform: {
        AR: "لماذا تستخدم منصتنا ؟",
        EN: "Why choose our platform?"
    },
    performance: {
        AR: "سرعه في الاداء",
        EN: "High Performance"
    },
    performance_content: {
        AR: "نوفير تجربة سلسة ومريحة للطالب والمعلمين مما يزيد من فعالية العملية التعليمية",
        EN: "Providing a smooth and comfortable experience for students and teachers, which enhances the effectiveness of the educational process."
    },
    maintaince: {
        AR: "خدمة صيانه",
        EN: "Ongoing Maintenance"
    },
    maintaince_content: {
        AR: "نضمن للعميل الصيانة الدورية وتحسين أداء المنصة بصفة مستمرة من خالل تحديث النظام وإصالح األخطاء",
        EN: "We ensure regular maintenance and continuous performance improvement of the platform through system updates and error fixes."
    },
    interactive: {
        AR: "دروس تفاعليه",
        EN: "Interactive Lessons"
    },
    interactive_content: {
        AR: "تضمين فيديوهات لشرح الدروس بطرق مرئية ورفع اإلختبارات من قبل المعلم ليجيب عنها الطالب ورفع المادة العلمية على المنصة",
        EN: "Incorporate videos to explain lessons visually, allow teachers to upload tests for students to complete, and provide a platform for uploading educational materials."
    },
    teacher_prof: {
        AR: "معلمون محترفون",
        EN: "Professional Educators"
    },

    teacher_prof_content: {
        AR: "نوفر للمعلمون المحترفون مستوى عاليًا من الجودة في تقديم المحتوى التعليمي، مما يضمن تجربة تعليمية ممتعه ومميزة",
        EN: "We provide professional educators with a high level of quality in delivering educational content, ensuring an engaging and exceptional learning experience."
    },
    aboutPlatform: {
        AR: "عن المنصه",
        EN: "About the Platform",
    },
    aboutPlatform_content: {
        AR: "نظام متكامل يهدف إلى توفير خدمات تعليمية شاملة عبر اإلنترنت. تعمل هذه المنصة على تقديم محتوى تعليمي متنوع ومبتكر للطالب من مختلف األعمار والمستويات عن طريق التفاعل المباشر مع المعلم وتقييم األداء عن طريق اإلختبارات وإنشاء مجتمع تعليمي على اإلنترنت حيث يمكن للطالب والمعلمين التفاعل وتبادل المعرفة والخبرات",
        EN: "An integrated system designed to provide comprehensive online educational services. This platform offers diverse and innovative educational content for students of all ages and levels through direct interaction with teachers, performance assessment via tests, and the creation of an online educational community where students and teachers can interact and share knowledge and experiences."
    },

    joinUs: {
        AR: "أنضم إلينا لتجربة تعليمية مميزة",
        EN: "Join us for a distinctive learning experience."
    },
    joinUsContent: {
        AR: "نضمن لك الوصول إلى منصتك وتصميم بسيط وسهل االستخدام يناسب أحتياجك ويتيح للمستخدمين التنقل بسهولة بين مختلف أجزاء المنصة استخدام ألوان وشعارات موحدة تعكس هوية المنصة وتجعلها مميزة",
        EN: "We guarantee you access to your platform with a simple, user-friendly design tailored to your needs. This ensures easy navigation between different parts of the platform, using consistent colors and logos that reflect the platform’s identity and make it stand out"
    },

    // Footer
    contentFooter: {
        AR: "تتبع المدرسة مناهج تعليمية حديثة ومتقدمة تركز على تطوير مهارات التفكير النقدي والإبداعي لدى الطلاب. تعتمد على أساليب تدريس تفاعلية لتحفيز الفضول والاكتشاف. يعمل بالمدرسة فريق من المعلمين ذوي الخبرة والكفاءة، والذين يسعون دومًا لتقديم الأفضل لطلابهم. يستفيد المعلمون من برامج تدريبية مستمرة لتحديث مهاراتهم وتطويرها.",
        EN: "The school follows modern and advanced curricula focused on developing students' critical and creative thinking skills. It employs interactive teaching methods to stimulate curiosity and discovery. The school is staffed by a team of experienced and skilled teachers who are committed to providing the best for their students. Teachers benefit from ongoing training programs to continually update and enhance their skills"
    },

    services: {
        AR: "الخدمات",
        EN: "Services"
    },
    Privacy: {
        AR: "سياسة الخصوصية والإستخدام",
        EN: "Privacy and Usage Policy"
    },
    Asked: {
        AR: "أسئلة متكررة",
        EN: "FAQ"
    },
    Technical_Support: {
        AR: "الدعم الفني",
        EN: "Services"
    },
    About_Us: {
        AR: "من نحن",
        EN: "About Us"
    },
    platform: {
        AR: "المنصه",
        EN: "Platform"
    },
    sendTels: {
        AR: "انضم ليصلك اخبارنا",
        EN: "Subscribe to receive our updates"
    },
    subscribeNow: {
        AR: "اشترك الان",
        EN: "Subscribe Now"
    },

    // Login && Register
    welcomeSentence: {
        AR: "OLP مرحبا بكم في",
        EN: "Welcome to the OLP"
    },
    pleaseLogin: {
        AR: "من فضلك قم بتسجيل الدخول",
        EN: "Please log in."
    },

    writeDetails: {
        AR: "سجل بيانات الاشتراكات",
        EN: "Register your subscription details."
    },
    writeDetailsStudent: {
        AR: "تسجيل بيانات الطالب",
        EN: "Student data registration."
    },
    writeDetailsParent: {
        AR: "تسجيل بيانات ولي الامر",
        EN: "Register parent information."
    },
    google: {
        AR: "تسجيل الدخول بواسطة جوجل",
        EN: "Login With Google"
    },
    facebook: {
        AR: "تسجيل الدخول بواسطة فيسبوك",
        EN: "Login With Google"
    },

    // Programs Page
    myPrograms: {
        AR: "برامجي الدراسيه",
        EN: "My Academic Programs"
    },

    choose_classes: {
        AR: "اختر من الصفوف",
        EN: "Choose from the classes"
    },

    form_contact: {
        AR: "هل تريد التحدث معنا ؟ هنا يتم الرد على استفسارات",
        EN: "Do you want to talk to us? Here we address your inquiries."
    },
    form_contact_info: {
        AR: "إذا كان لديك أسئلة أو تعليقات أو اقتراحات؟ ما عليك سوى ملء النموذج وسنتواصل معك قريبًا.",
        EN: "If you have any questions, comments, or suggestions, just fill out the form and we'll get back to you soon.",
    },
    firstName: {
        AR: "الاسم الاول",
        EN: "First Name"
    },
    message: {
        AR: "اكتب رسالتك هنا",
        EN: "Write Your Message Here"
    },

    // Teachers Pages
    teachers: {
        AR: "المعلمون",
        EN: "Teachers"
    },
    details: {
        AR: "التفاصيل",
        EN: "Details"
    },
    info_subscribe: {
        AR: "لتتمكن من التسجيل في البرامج، عليك متابعة المعلم.",
        EN: "To enroll in the programs, you need to follow the teacher."
    },
    teach: {
        AR: "المعلم",
        EN: "Teacher"
    },
    aboutTeacher: {
        AR: "نبذه عن المعلم",
        EN: "About Teacher"
    },
    prevPage: {
        AR: "عوده الي الصفحه السابقه",
        EN: "Return to the previous page."
    },
    booking: {
        AR: "احجز الان",
        EN: "Book Now"
    },
    following: {
        AR: "متابعه",
        EN: "follow"
    },
    subComplete: {
        AR: "تم الاشتراك",
        EN: "Subscription completed."
    },
    send_message_now: {
        AR: "ارسل رساله",
        EN: "Send Message"
    },
    commonQueuestions: {
        AR: "الاسئله الشائعه",
        EN: "Frequently Asked Questions"
    },
    sharedTwo: {
        AR: "عن المنصة ، من نحن ؟",
        EN: "About the Platform, Who Are We?"
    },
    sharedThree: {
        AR: "لديك مشكلة ؟ أذهب للدعم الفني",
        EN: " Have a problem? Go to Technical Support"
    },

    // Details Program
    programDetails: {
        AR: "تفاصيل البرنامج",
        EN: "Program Details"
    },
    material: {
        AR: "الماده",
        EN: "Material"
    },
    material_name: {
        AR: "اسم الماده",
        EN: "Material Name"
    },
    return_main_page: {
        AR: "العوده الي الصفحه الرئيسيه",
        EN: "Return To Main Page"
    },
    doneSubscribe: {
        AR: "قم بالاشتراك الان",
        EN: "Subscribe Now"
    },
    subscripeProgram: {
        AR: "اشتراك البرنامج",
        EN: "Subscribe Program"
    },
    infoOne: {
        AR: "برجاء دفع مبلغ ",
        EN: "Please pay an amount of."
    },
    infoTwo: {
        AR: "على محفظة الرقم الموضح",
        EN: "to the wallet number shown"
    },
    cashIsal: {
        AR: "قم برفع صورة من إيصال أو رسالة الدفع",
        EN: "Please upload a picture of the receipt or payment message."
    },
    vodaphone: {
        AR: "فودافون",
        EN: "vodaphone"
    },
    code: {
        AR: "كود",
        EN: "Code"
    },
    chooseIsal: {
        AR: "أختر صورة الإيصال أو رسالة الدفع",
        EN: "Please select the receipt or payment message image."
    },
    uploadImage: {
        AR: "ارفع الصوره",
        EN: "Upload Image"
    },
    forSubscripe: {
        AR: "اشتراك",
        EN: "Subscribe"
    },
    infoCode: {
        AR: "الاشتراك بنظام الاكواد ملحوظة: لفتح كل درس يتطلب حصولك على كود خاص به",
        EN: "Subscription to the code system. Note: To access each lesson, you need to obtain a specific code for it."
    },

    // For Dashboard
    subscriptionsRequest: {
        AR: "طلبات الإشتراك",
        EN: "Subscription requests"
    },
    messages: {
        AR: "الرسائل",
        EN: "Messages"
    },
    imagePhoto: {
        AR: "صورة البرنامج",
        EN: "Program Image"
    },
    subscriptions: {
        AR: "الاشتراكات",
        EN: "subscriptions"
    },
    students: {
        AR: "طلابي",
        EN: "My Students",
    },
    programs: {
        AR: "الكورسات",
        EN: "Courses"
    },
    create_Program: {
        AR: "انشاء برنامج",
        EN: "Create Program"
    },
    create_course: {
        AR: "انشاء كورس",
        EN: "Create Course"
    }
    ,
    classes: {
        AR: "الفصول",
        EN: "Classes"
    },
    materials: {
        AR: "المواد",
        EN: "Materials"
    },
    personalInformation: {
        AR: "معلومات الصفحه الشخصيه",
        EN: "Personal Information"
    },
    AwarenessTips: {
        AR: "نصائح توعيه",
        EN: "Awareness Tips"
    },
    aboutMe: {
        AR: "نبذه عني",
        EN: "About Me"
    },
    phone: {
        AR: "التليفون",
        EN: "Phone"
    },
    email: {
        AR: "الايميل",
        EN: "Email"
    },
    editPersonalInfo: {
        AR: "تعديل المعلومات الشخصيه",
        EN: "Edit Personal Information"
    },
    material_test: {
        AR: "اختبار الماده",
        EN: "Material Test"
    },
    dateTest: {
        AR: "تاريخ الاختبار",
        EN: "Date Test"
    },
    Tries: {
        AR: "عدد المحاولات",
        EN: "Number Of Tries"
    },
    mark: {
        AR: "الدرجه",
        EN: "Mark"
    },
    hour: {
        AR: "الساعه",
        EN: "Hour"
    },
    communicateSupport: {
        AR: ": طرق التواصل مع الدعم الفني",
        EN: "Ways to Contact Technical Support"
    },
    sendReasons: {
        AR: "البريد الإلكتروني: يمكنك إرسال استفساراتك أو مشاكلك عبر البريد الإلكتروني إلى عنوان الدعم المحدد.",
        EN: "Email: You can send your inquiries or issues via email to the specified support address.",
    },
    ticketSystem: {
        AR: ": نظام التذاكر",
        EN: "Ticket System"
    },
    reach: {
        AR: "الوصول: عادةً ما يكون هناك قسم الدعم أو تقديم تذكرة.",
        EN: "Access: There is usually a Support section or Submit a Ticket"
    },
    steps: {
        AR: ": خطوات الحصول على الدعم الفني",
        EN: "Steps to Get Technical Support"
    },
    selectProplem: {
        AR: ": تحديد المشكلة",
        EN: "Identify the Problem"
    },
    selectAllProplems: {
        AR: "قم بتحديد المشكلة التي تواجهها بوضوح، مثل مشكلة في تسجيل الدخول، مشكلة في تشغيل الفيديوهات، أو مشكلة في الدفع.",
        EN: "Clearly identify the issue you're facing, such as login issues, video playback problems, or payment issues."
    },
    collectInformation: {
        AR: "جمع المعلومات الازمه",
        EN: "Gather Necessary Information"
    },
    infoCollectInfo: {
        AR: "اجمع معلومات مفصلة حول المشكلة مثل رسائل الخطأ، خطوات إعادة الإنتاج، وأي لقطات شاشة قد تكون مفيدة.",
        EN: "Gather detailed information about the problem, such as error messages, reproduction steps, and any useful screenshots."
    },
    joinUsTeacher: {
        AR: "انضم",
        EN: "Join "
    },

    us: {
        EN: "To Us",
        AR: "الينا",
    },

    joinUsTeacherData: {
        AR: "إذا كنت معلم وتبحث عن منصة تنشئ لك صفك التعليمي",
        EN: "If you are a teacher and looking for a platform to create your educational class for you"
    },

    teacherSub: {
        AR: "اشتراكات خدمات المعلم",
        EN: "Teacher services subscriptions"
    },
    serviceName: {
        AR: "اسم الخدمه",
        EN: "Service Name"
    },
    serviceDet: {
        AR: "تفاصيل الخدمه",
        EN: "Service details"
    },
    serviceExpire: {
        AR: "انتهاء الخدمه",
        EN: "End of service"
    },
    serviceRenew: {
        AR: "تجديد الخدمه",
        EN: "Service Renewal"
    },
    priceMonth: {
        AR: "سعر الخدمه الشهري",
        EN: "Monthly service price"
    },
    priceAnual: {
        AR: "سعر الخدمه السنوي",
        EN: "Annual service price"
    },
    discountPriceMonth: {
        AR: "سعر الخصم الشهري",
        EN: "Month discount rate"
    },
    discountPriceAnnual: {
        AR: "سعر الخصم السنوي",
        EN: "Annual discount rate"
    },

    pricemonthDir: {
        AR: "السعر الشهري",
        EN: "Month Price"
    },
    annualPrice: {
        AR: "السعر السنوي",
        EN: "Annual Price"
    },
    egp: {
        AR: "جنيه",
        EN: "EGP"
    },

    maxValue: {
        AR: "اقصي عدد لطلاب",
        EN: "Max Value Students"
    },
    maxGiga: {
        AR: "اقصي عدد للجيجا",
        EN: "Max Value Giga"
    },
    trialDuration: {
        AR: "مدة الفتره التجريبيه",
        EN: "Duration of the trial period"
    },
    noSubs: {
        AR: "لا يوجد اشتراكات بعد",
        EN: "No Subscription"
    },
    serviceTeacher: {
        AR: "خدمات المعلم",
        EN: "Services Teachers"
    },
    renewPerMonth: {
        AR: "تجديد شهري",
        EN: "Monthly renewal"
    },
    renewPerAnnual: {
        AR: "تجديد سنوي",
        EN: "Annual renewal"
    },
    renewWord: {
        AR: "تجديد",
        EN: "Renew"
    },
    studetn: {
        AR: "طالب",
        EN: "Studnet"
    },
    giga: {
        AR: "جيجا",
        EN: "Giga"
    },
    today: {
        AR: "يوم",
        EN: "Today"
    },

    infoServiceType: {
        AR: "لتقديم خدمات مخصصة للمعلمين  نصمم لك مجموعة متنوعة من الخدمات التي تساهم في تعزيز دور المعلم وتسهيل إدارة العملية التعليمية من خلال باقات المعلم",
        EN: "To provide customized services for teachers, we design for you a variety of services that contribute to enhancing the role of the teacher and facilitating the management of the educational process through teacher packages."
    },
    storagesPackage: {
        AR: "باقات المساحات التخزينيه",
        EN: "Storage space packages"
    },
    packagesStd: {
        AR: "باقة عدد الطلاب لحساب المعلم",
        EN: "Number of students package for the teacher’s account"
    },
    infoStoragePack: {
        AR: "نقدم خيارات متنوعة تناسب احتياجات المعلم المختلفة من حيث المساحة التخزينية لإدارة الملفات والمحتويات التعليمية",
        EN: "We offer various options that suit different teacher needs in terms of storage space for managing files and educational content"
    },
    infoPackStd: {
        AR: "  نقدم خيارات متنوعة تناسب احتياجات المعلم المختلفة من حيث عدد الطلاب والميزانية والمحتوى المقدم.",
        En: "We offer various options to suit different teacher needs in terms of number of students, budget, and content provided."
    },
    serviceType: {
        AR: "نوع الخدمه",
        EN: "Service Type"
    },
    aiWrite: {
        AR: "جيمناي يكتب الان.....",
        EN: "Ai Write Now....."
    },

    shouldLogin: {
        AR: "يجب تسجيل الدخول",
        EN: "Should Login Now"
    },
    helpAss: {
        AR: "OLP المساعد الشخصي",
        EN: "Seshat Personal Assistant"
    },
    helpnafsy: {
        AR: "OLP المساعد النفسي",
        EN: "Seshat Psychological assistant "
    },
    helpsicology: {
        AR: "المساعد النفسي",
        EN: ""
    },
    convAi: {
        AR: "التحدث مع الذكاء الاصطناعي",
        EN: "Chat With Ai"
    },
    seshatAitry: {
        AR: "تجربة Advanced Seshat Ai",
        EN: "Experience Advanced Seshat AI"
    },

    close: {
        AR: "الغاء",
        EN: "Close"
    },

    save: {
        AR: "انشاء",
        EN: "Create"
    },


    disc_price: {
        AR: "سعر الخصم",
        EN: "Discount Price"
    },
    choose_lesson: {
        AR: "اختر الدروس",
        EN: "Choose Lessons"
    },
    lessons: {
        AR: "الدروس",
        EN: "Lessons"
    }
    ,

    materialTeacher: {
        AR: "معلم الماده",
        EN: "Mterial Teacher",
    },


    disPrice: {
        AR: `(سعر الخصم يعني السعر بالطبع بعد الخصم: إذا كان السعر 20 دولارًا والخصم 2 دولارًا، فإن معرف سعر الخصم 18 دولارًا)`,
        EN: "(discount price mean price of course after discount : if price 20$ and discount 2 $ then discount price id 18$)"
    },

    // Complete Register Translation
    enterclassRoom: {
        AR: "ادخل مراحل الصف الدراسي",
        EN: `Enter grade levels`
    },
    classRoomInfo: {
        AR: "قم بادخال المرحلة التعليمية والمواد التي تدرسها لكل صف دراسي",
        EN: `Enter the educational stage and subjects you teach for each grade`
    },
    chooseCategory: {
        AR: "اختر الفئه",
        EN: `Choose branch`
    },

    level: {
        AR: "المستوي",
        EN: `Level`
    },
    chooseLevel: {
        AR: "اختر المستوي",
        EN: `Choose Level`
    },

    mainMaterials: {
        AR: "المواد الرئيسيه",
        EN: `Main Materials`
    },
    chooseMaterial: {
        AR: "اختر الماده",
        EN: `Choose Material`
    },

    loading: {
        AR: "جاري التحميل",
        EN: `Loading`
    },

    done: {
        AR: "تم",
        EN: `Done`
    },

    addLesson: {
        AR: "اضافة الدرس",
        EN: "Add Lesson"
    },

    edit: {
        AR: "تعديل",
        EN: "Edit"
    },

    privacy_title: {
        AR: "سياسة الخصوصية لمنصة OLP",
        EN: "OLP Platform Privacy Policy",
    },
    privacy_intro_title: {
        AR: "مقدمة",
        EN: "Introduction",
    },
    privacy_intro_content: {
        AR: "مرحبًا بكم في منصة OLP. نحن ملتزمون بحماية خصوصيتك وضمان سرية وأمان بياناتك الشخصية. توضح هذه السياسة كيفية جمعنا واستخدامنا ومشاركتنا لبياناتك الشخصية عند استخدامك لمنصتنا.",
        EN: "Welcome to OLP Platform. We are committed to protecting your privacy and ensuring the confidentiality and security of your personal data. This policy explains how we collect, use and share your personal data when you use our platform.",

    },
    data_collection_title: {
        AR: "البيانات التي نجمعها",
        EN: "Data We Collect",

    },
    registration_data: {
        AR: "بيانات التسجيل: تشمل الاسم، البريد الإلكتروني، كلمة المرور، رقم الهاتف، وعنوان السكن",
        EN: "Registration data: includes name, email, password, phone number, and residential address",

    },
    payment_data: {
        AR: "بيانات الدفع: مثل تفاصيل بطاقة الائتمان أو الحساب البنكي، سجل المعاملات، وتاريخ الدفع",
        EN: "Payment data: such as credit card or bank account details, transaction history, and payment dates",
    },
    usage_data: {
        AR: "بيانات الاستخدام: تتضمن تاريخ التسجيل، الأنشطة داخل المنصة، الأجهزة المستخدمة",
        EN: "Usage data: includes registration date, platform activities, and devices used"
    },
    cookies_data: {
        AR: "ملفات تعريف الارتباط (Cookies): لجمع بيانات لتحسين تجربة المستخدم",
        EN: "Cookies: to collect data for improving user experience"
    },
    data_collection_reasons: {
        AR: "أسباب جمع البيانات",
        EN: "Reasons for Data Collection"
    },
    services_provision: {
        AR: "توفير الخدمات",
        EN: "Services Provision"
    },
    account_management: {
        AR: "إدارة الحسابات",
        EN: "Account Management"
    },
    payment_processing: {
        AR: "معالجة الدفعات",
        EN: "Payment Processing"
    },
    technical_support: {
        AR: "تقديم الدعم الفني",
        EN: "Technical Support"
    },
    service_improvement: {
        AR: "تحسين الخدمة",
        EN: "Service Improvement"
    },
    data_analysis: {
        AR: "تحليل بيانات الاستخدام لتطوير المنصة",
        EN: "Usage data analysis for platform development"
    },
    user_experience: {
        AR: "تخصيص تجربة المستخدم بناءً على تفضيلاته",
        EN: "Customizing user experience based on preferences"
    },
    legal_compliance: {
        AR: "الامتثال للقوانين",
        EN: "Legal Compliance"
    },
    payment_records: {
        AR: "حفظ سجلات الدفع لأغراض الضرائب والمحاسبة",
        EN: "Maintaining payment records for tax and accounting purposes"
    },
    





    data_protection: {
        AR: "حماية البيانات",
        EN: "Data Protection"
    },
    security_measures: {
        AR: "نتخذ جميع التدابير الأمنية اللازمة لحماية بياناتك الشخصية من الوصول غير المصرح به، أو التعديل، أو الكشف عنها. تتضمن إجراءاتنا:",
        EN: "We take all necessary security measures to protect your personal data from unauthorized access, modification, or disclosure. Our procedures include:"
    },
    encryption: {
        AR: "استخدام تقنيات التشفير الحديثة",
        EN: "Using modern encryption technologies"
    },
    firewalls: {
        AR: "جدران الحماية",
        EN: "Firewalls"
    },
    system_monitoring: {
        AR: "مراقبة الأنظمة بشكل دوري",
        EN: "Regular system monitoring"
    },
    data_sharing: {
        AR: "مشاركة البيانات",
        EN: "Data Sharing"
    },
    payment_providers: {
        AR: "مع مقدمي خدمات الدفع: لتسهيل المعامالت المالية مع ضمان التزامهم بسياسات الخصوصية",
        EN: "With payment service providers: to facilitate financial transactions while ensuring their compliance with privacy policies"
    },
    authorities: {
        AR: "مع السلطات المختصة : في حال طلب رسمي بناءً على أمر قضائي أو تحقيق قانوني",
        EN: "With relevant authorities: in case of formal request based on court order or legal investigation"
    },
    user_rights: {
        AR: "حقوق المستخدم",
        EN: "User Rights"
    },
    data_access: {
        AR: "الوصول إلى بياناتك: يمكنك طلب نسخة من البيانات التي نحتفظ بها",
        EN: "Data access: You can request a copy of the data we hold"
    },
    data_correction: {
        AR: "تصحيح البيانات : يحق لك طلب تصحيح أي معلومات غير دقيقة",
        EN: "Data correction: You have the right to request correction of any inaccurate information"
    },
    data_deletion: {
        AR: "حذف البيانات : يمكنك طلب حذف بياناتك الشخصية، باستثناء ما يلزمنا الاحتفاظ به قانونياً",
        EN: "Data deletion: You can request deletion of your personal data, except what we are legally required to retain"
    },
    objection: {
        AR: "الاعتراض: يحق لك الاعتراض على معالجة بياناتك لأغراض التسويق",
        EN: "Objection: You have the right to object to processing your data for marketing purposes"
    },
    terms_of_use: {
        AR: "شروط الاستخدام لمنصة OLP",
        EN: "OLP Platform Terms of Use"
    },
    registration: {
        AR: "التسجيل",
        EN: "Registration"
    },
    account_requirement: {
        AR: "يجب أن يكون لديك حساب شخصي على منصة OLP للوصول إلى الخدمات",
        EN: "You must have a personal account on the OLP platform to access services"
    },
    accurate_data: {
        AR: "يجب تقديم بيانات صحيحة ودقيقة أثناء عملية التسجيل",
        EN: "Accurate and precise data must be provided during registration"
    },
    services_provided: {
        AR: "الخدمات المقدمة",
        EN: "Services Provided"
    },
    olp_services: {
        AR: "تشمل خدمات OLP",
        EN: "OLP services include"
    },
    educational_services: {
        AR: "خدمات تعليمية مخصصة للطالب والمعلمين",
        EN: "Customized educational services for students and teachers"
    },
    storage_spaces: {
        AR: "مساحات تخزينية آمنة",
        EN: "Secure storage spaces"
    },
    video_conferences: {
        AR: "خدمة المؤتمرات المرئية",
        EN: "Video conferencing service"
    },
    teacher_services: {
        AR: "خدمات المعلمين",
        EN: "Teacher Services"
    },
    student_services: {
        AR: "خدمات الطالب",
        EN: "Student Services"
    },
    payment: {
        AR: "الدفع",
        EN: "Payment"
    },
    refund: {
        AR: "استرداد الاموال",
        EN: "Refund"
    },
    responsibility: {
        AR: "المسؤولية",
        EN: "Responsibility"
    },
    service_termination: {
        AR: "إنهاء الخدمة",
        EN: "Service Termination"
    },
    acceptable_use: {
        AR: "سياسة الاستخدام المقبول",
        EN: "Acceptable Use Policy"
    },
    intellectual_property: {
        AR: "حقوق الملكية الفكرية",
        EN: "Intellectual Property Rights"
    },
    disclaimer: {
        AR: "إخلاء المسؤولية",
        EN: "Disclaimer"
    },
    dispute_resolution: {
        AR: "حل النزاعات",
        EN: "Dispute Resolution"
    },
    contact_info: {
        AR: "للاستفسارات حول سياسة الخصوصية أو شروط الاستخدام",
        EN: "For inquiries about privacy policy or terms of use"
    },
    emailAccess: {
        AR: "البريد الإلكتروني: info@osbash.com",
        EN: "Email: info@osbash.com"
    },
    phoneAccess: {
        AR: "رقم الهاتف/واتساب: 201551661850+",
        EN: "Phone/WhatsApp: +201551661850"
    },

    


    teacher_subscription_info: {
        AR: "عند اشتراك المعلم في المنصة، يمكنه تقديم خدماته التعليمية وفقاً لنوع الاشتراك الذي يختاره",
        EN: "When teachers subscribe to the platform, they can provide educational services according to their chosen subscription type"
    },
    student_limit_service: {
        AR: "خدمة تحديد عدد الطلاب: تتيح للمعلم تحديد عدد الطلاب المسموح له باستقبالهم",
        EN: "Student limit service: Allows teachers to set the number of students they can accept"
    },
    storage_service: {
        AR: "خدمة المساحات التخزينية: تتيح للمعلم رفع محتوى تعليمي على المنصة",
        EN: "Storage service: Allows teachers to upload educational content to the platform"
    },
    student_benefits: {
        AR: "يمكن للطالب الاستفادة من المنصة عن طريق الاشتراك في أي كورس أو محتوى تعليمي يختاره",
        EN: "Students can benefit from the platform by subscribing to any course or educational content of their choice"
    },
    subscription_fees: {
        AR: "يتم تحديد رسوم الاشتراك وطريقة الدفع في صفحة الأسعار الخاصة بالمنصة",
        EN: "Subscription fees and payment methods are specified on the platform's pricing page"
    },
    payment_schedule: {
        AR: "يجب تسوية الدفعات في المواعيد المحددة لتجنب انقطاع الخدمة",
        EN: "Payments must be settled on scheduled dates to avoid service interruption"
    },
    user_responsibility: {
        AR: "يتحمل المستخدم المسؤولية الكاملة عن المحتوى الذي ينشره على المنصة",
        EN: "Users bear full responsibility for the content they publish on the platform"
    },
    platform_protection: {
        AR: "تلتزم المنصة بحماية البيانات، لكنها غير مسؤولة عن أي أضرار تنجم عن استخدام المستخدم للخدمة بشكل غير صحيح",
        EN: "The platform is committed to data protection but is not responsible for damages resulting from improper use of the service"
    },
    refund_policy: {
        AR: "تخضع طلبات استرداد الأموال لشروط محددة يتم توضيحها في سياسة الاسترداد",
        EN: "Refund requests are subject to specific conditions outlined in the refund policy"
    },
    cancellation_right: {
        AR: "يحق للمستخدم إلغاء اشتراكه في أي وقت من خلال إعدادات الحساب",
        EN: "Users have the right to cancel their subscription at any time through account settings"
    },
    account_suspension: {
        AR: "تحتفظ المنصة بالحق في تعليق أو إنهاء الحساب في حال انتهاك شروط الاستخدام",
        EN: "The platform reserves the right to suspend or terminate accounts for terms of use violations"
    },
    prohibited_behavior: {
        AR: "يُمنع أي سلوك غير قانوني أو غير مقبول",
        EN: "Any illegal or unacceptable behavior is prohibited"
    },
    inappropriate_content: {
        AR: "نشر محتوى مسيء أو غير لائق",
        EN: "Publishing offensive or inappropriate content"
    },
    hate_promotion: {
        AR: "الترويج للكراهية أو العنف",
        EN: "Promoting hatred or violence"
    },
    intellectual_property_violation: {
        AR: "التعدي على حقوق الملكية الفكرية للآخرين",
        EN: "Infringing on others' intellectual property rights"
    },
    content_ownership: {
        AR: "جميع المحتويات المنشورة على المنصة مملوكة لمنصة OLP أو للجهات المرخصة",
        EN: "All content published on the platform is owned by OLP platform or licensed entities"
    },
    content_protection: {
        AR: "يُمنع نسخ أو توزيع أو تعديل أي محتوى دون إذن مسبق",
        EN: "Copying, distributing, or modifying any content without prior permission is prohibited"
    },
    service_provision: {
        AR: "يتم تقديم خدمات المنصة \"كما هي\" دون أي ضمانات صريحة أو ضمنية",
        EN: "Platform services are provided \"as is\" without any express or implied warranties"
    },
    liability_limitation: {
        AR: "المنصة ليست مسؤولة عن أي أضرار مباشرة أو غير مباشرة قد تنتج عن استخدام الخدمات",
        EN: "The platform is not liable for any direct or indirect damages that may result from using the services"
    },
    dispute_resolution: {
        AR: "يتم حل أي نزاع ينشأ بين المستخدم والمنصة ودياً",
        EN: "Any disputes between users and the platform will be resolved amicably"
    },
    legal_jurisdiction: {
        AR: "في حال تعذر الوصول إلى حل، تُحال النزاعات إلى الجهات القانونية المختصة في الدولة المسجلة فيها المنصة",
        EN: "If a resolution cannot be reached, disputes will be referred to the competent legal authorities in the platform's registered country"
    },
    statusTrans:{
        AR:"حالة المعامله",
        EN:"Transaction Status"
    },
    typeTrans: {
        AR: "نوع المعامله",
        EN: "Transaction Type"
    },
    selectCategory: {
        AR: "اختيار الفئة",
        EN: "Select Category"
    },
    selectStage: {
        AR: "اختيار المرحلة",
        EN: "Select Stage"
    },
    selectGrade: {
        AR: "اختيار الصف",
        EN: "Select Grade"
    },
    selectSubject: {
        AR: "اختيار المادة",
        EN: "Select Subject"
    },
    selectChapters: {
        AR: "اختيار الفصول",
        EN: "Select Chapters"
    },
    paymentData: {
        AR: "الدفع",
        EN: "Payment"
    },
    donePay: {
        AR: "تم",
        EN: "Done"
    },

    next:{
        AR:"التالي",
        EN:"Next"
    },
    prev:{
        AR:"السابق",
        EN:"Previous"
    }


};

// AR:'',
// EN:'',
// FR:''