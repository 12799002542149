import { acceptWithdraw, cancleWithdraw, createWithdraw, getMyWithdraws, getWithdraws, rejectWithdraw } from "../utils/withdraw"

export const INTIALIZE_WITHDRAWS = 'INTIALIZE_WITHDRAWS'
export const ACCEPT_WITHDRAWS = 'ACCEPT_WITHDRAWS'
export const GET_MY_WITHDRAWS = 'GET_MY_WITHDRAWS'
export const CREATE_WITHDRAW = 'CREATE_WITHDRAW'
export const CANCEL_WITHDRAWS = 'CANCEL_WITHDRAWS'
const inetializeWithdraws = (withdraws) => {
    return {
        type: INTIALIZE_WITHDRAWS,
        withdraws
    }
}
export const Get_All_Withdraws = (userId) => {
    return dispatch => getWithdraws(userId).then(res => {
        // console.log('res withdraw', res)
        if (res.data.case) {
            dispatch(inetializeWithdraws(res.data.withdraws));
        }
        return res
    })
}



// Accept Withdraw

const AcceptWithdraw = (withdraw)=>{
    return {
        type: ACCEPT_WITHDRAWS,
        withdraw
    }
}
const CancelWithdraw = (withdraw)=>{
    return {
        type: CANCEL_WITHDRAWS,
        withdraw
    }
}

export const Accept_Withdraws_Act = (id,userId) => {
    return dispatch => acceptWithdraw(id,userId).then(res => {
        // console.log('res withdraw', res)
        if (res.data.case) {
            dispatch(AcceptWithdraw(res.data.withdraw));
        }
        return res
    })
}
export const Reject_Withdraws_Act = (data,id,userId) => {
    return dispatch => rejectWithdraw(data,id,userId).then(res => {
        // console.log('res withdraw', res)
        if (res.data.case) {
            dispatch(AcceptWithdraw(res.data.withdraw));
        }
        return res
    })
}
export const Cancel_Withdraws_Act = (id,userId) => {
    return dispatch => cancleWithdraw(id,userId).then(res => {
        // console.log('res withdraw', res)
        if (res.data.case) {
            dispatch(AcceptWithdraw(res.data.withdraw));
        }
        return res
    })
}
export const Cancel_Withdraws_Act_Teacher = (id,userId) => {
    return dispatch => cancleWithdraw(id,userId).then(res => {
        // console.log('res withdraw', res)
        if (res.data.case) {
            dispatch(CancelWithdraw(res.data.withdraw));
        }
        return res
    })
}




// Teacher Withdraws

const getmywithdraws = (withdraws) => {
    return {
        type: GET_MY_WITHDRAWS,
        withdraws
    }
}

export const Get_My_Withdraw_Teacher = (userId) => {
    return dispatch => getMyWithdraws(userId).then(res => {
        console.log('res withdraw Teacher', res)
        if (res.data.case) {
            dispatch(getmywithdraws(res.data.withdraws));
        }
        return res
    })
}



const createWithdrawTeacher = (withdraw) => {
    return {
        type: CREATE_WITHDRAW,
        withdraw
    }
}

export const Create_Withdraw_Teacher = (data,userId) => {
    return dispatch => createWithdraw(data,userId).then(res => {
        console.log('res withdraw Teacher', res)
        if (res.data.case) {
            dispatch(createWithdrawTeacher(res.data.withdraw));
        }
        return res
    })
}
