import React, { useContext, useEffect, useState } from 'react'
import Timeline from './Timeline'
import PageContainer from '../layout/PagecContainer'
import { Link, useNavigate, useParams } from 'react-router-dom'
import "./timeline.css"
import SharedImage from './SharedImage'
import { Swiper, SwiperSlide } from 'swiper/react';
import teacherCard from "../../assets/teacherRevers.png"
import 'swiper/css';
import { useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { getClassesMainMatsActive } from '../../utils/mainMaterial'
import { ErrorMsg } from '../shared/modules/Notifications'
import CardClass from './CardClass'
import { ThemeContext } from '../../ThemeProvider'
import { HomePage } from '../../utils/Translation/HomePage'
const Classselect = () => {

    const params = useParams();
    const { theme } = useContext(ThemeContext);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [classesData, setClasses] = useState([]);
    const [loading, setLoading] = useState(false)

    const handleBranchClick = (branchId) => {
        setSelectedBranch(branchId);
    };

    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(-1)
    }

    const { lang, dir } = useSelector(({ language }) => ({
        lang: language.lang,
        dir: language.dir,
    }));
    const handleGetAllMainMats = async () => {
        try {
            setLoading(true)
            const res = await getClassesMainMatsActive(params?.teacher);
            console.log(res)
            if (res?.data?.case) {
                setClasses(res?.data?.classes)
            }
        } catch (e) {

        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        handleGetAllMainMats();
    }, [])

    const loggedUser = useSelector((state) => state.loggedUser);
    console.log(loggedUser)

    useEffect(() => {
        if (!loggedUser.auth) {
            // Store current route before redirecting
            const currentPath = window.location.pathname;
            localStorage.setItem('redirectPath', currentPath);
            ErrorMsg('يجب تسجيل الدخول')
            setTimeout(() => {
                navigate('/login');
            }, 1000);
        }
    }, [loggedUser.auth, navigate]);


    if (loading) {
        return (
            <div className="loading-screen" style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                backgroundColor: '#f5f5f5'
            }}>
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        );
    }
    return (
        <div>
            <PageContainer>
                <div style={{ paddingTop: "120px" }}>
                    <div className='container'>
                        <Timeline activeStep={5} />
                        <div className='row justify-content-between align-items-center' style={{ marginTop: "180px" }}>
                            {/* <div className='col-lg-2 col-sm-12 col-md-12 '>
                                <SharedImage type={"course"} />
                            </div> */}
                            {/* <div className='col-lg-8 col-sm-12 col-md-12 ' style={{ background: "white", padding: "30px", borderRadius: "10px" }}>
                                <Swiper
                                    spaceBetween={50}
                                    slidesPerView={3}

                                >
                                    {
                                        classesData?.map((item, index) => {
                                            return (
                                                <SwiperSlide key={index}>
                                                    <div className={`text-center cardTeacher font cardClass shadow font ${selectedBranch === item?._id ? 'active' : ''}`}
                                                        onClick={() => handleBranchClick(item?._id)}
                                                    >
                                                        <img src={teacherCard} alt={`data`} className="card-image" />
                                                        <div className="card-content">
                                                            <p className="teacher-name">الفصل : {item?.name}</p>
                                                            <p className="lessons-count">
                                                                {item?.packId?.description}
                                                            </p>
                                                            <Link to={`/reservation-system/male/${params?.stage}/${params?.classroom}/${params?.subject}/${params?.teacher}/${item?._id}`} className="preview-button">معاينة الفصل</Link>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </div> */}
                            <div className='col-lg-12 col-sm-12 col-md-12 ' style={{ background: "white", padding: "30px", borderRadius: "10px" }}>
                                <div className='row justify-content-between align-items-center'>
                                    {
                                        classesData?.map((item, index) => {
                                            return (
                                                <div className={`col-lg-6 col-sm-12 col-md-12 text-center`} key={index}>
                                                    <CardClass
                                                        key={index}
                                                        item={item}
                                                        selectedBranch={selectedBranch}
                                                        handleBranchClick={() => handleBranchClick(item?._id)}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className='m-auto text-center mt-4 mb-5 font'>
                            <button onClick={handleNavigate} className='btn w-25 mx-2 prev'
                                style={{ color: theme === "light" ? "#071D5B" : "white", }}
                            >{HomePage.prev[lang]}</button>


                            <Link to={`/reservation-system/male/${params?.stage}/${params?.classroom}/${params?.subject}/${params?.teacher}/${selectedBranch}`}
                                className={`btn w-25 ${!selectedBranch ? 'disabled' : ''}`}
                                style={{
                                    background: "#071D5B",
                                    color: "white",
                                    opacity: !selectedBranch ? "0.5" : "1",
                                    pointerEvents: !selectedBranch ? "none" : "auto"
                                }}
                            >{HomePage.next[lang]}</Link>
                        </div>
                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default Classselect
