import React, { useContext } from "react";
import "../../styles/shared.css"
import LogoRhaya from '../../assets/logo-white.png'
import { Link } from "react-router-dom";
import { ThemeContext } from "../../ThemeProvider";
import { useSelector } from "react-redux";
import { HomePage } from "../../utils/Translation/HomePage";
import { loginTrans } from "../../utils/Translation/loginAndRegister";

const FooterWidgets = () => {

    const { theme } = useContext(ThemeContext);
    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    const textAlign = dir === "rtl" ? "left" : "right";

    return (
        <div className={`footer ${theme}`} dir={`${dir}`}>
            <div className="row footerDiv font mt-5 justify-content-between align-items-center">
                <div className="col-lg-3 col-md-6 col-sm-12">
                    <div  style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                        <img src={LogoRhaya} alt="Logo Rhaya" style={{ width: "40%" }} />
                        <p style={{ color: "white" }}>
                            {HomePage.contentFooter[lang]}
                        </p>
                    </div>
                </div>
                <div className="col-lg-9 col-md-6 col-sm-12">
                    <div
                        className="row justify-content-between align-items-center col-12"
                        style={{ flexWrap: "wrap", width: "100%" }}
                    >
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div>
                                <aside className="widget widget_footer">

                                    <ul className="ps-list--link">
                                        <li className="widget-title" style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>{HomePage.services[lang]}</li>
                                        <li style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                                            <Link to="/contact-us" style={{ textDecoration: "none" }}>
                                                <span>{HomePage.Contact_us[lang]}</span>
                                            </Link>
                                        </li>
                                        <li style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                                            <Link to="/privacy" style={{ textDecoration: "none" }}>
                                                <span>{HomePage.Privacy[lang]}</span>
                                            </Link>
                                        </li>
                                        <li style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                                            <Link to="/faq" style={{ textDecoration: "none" }}>
                                                <span>{HomePage.Asked[lang]}</span>
                                            </Link>
                                        </li>
                                        <li style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                                            <Link to="/support">
                                                <span>{HomePage.Technical_Support[lang]}</span>
                                            </Link>
                                        </li>
                                        <li style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                                            <Link to="/about-us">
                                                <span>{HomePage.About_Us[lang]}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </aside>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div>
                                <aside className="widget widget_footer">

                                    <ul className="ps-list--link"  style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                                        <li className="widget-title" style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>{HomePage.platform[lang]}</li>
                                        <li style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                                            <a href="*">
                                                <span>{HomePage.subjects[lang]}</span>
                                            </a>
                                        </li>
                                        <li style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                                            <Link to="/support">
                                                <span>{HomePage.Technical_Support[lang]}</span>
                                            </Link>
                                        </li>
                                        <li style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                                            <a href="*">
                                                <span>{HomePage.Contact_us[lang]}</span>
                                            </a>
                                        </li>
                                    </ul>
                                </aside>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div>
                                <aside className="widget widget_footer">

                                    <div className="widget_content">

                                        <div className="ps-list--social" style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                                            <p>{HomePage.sendTels[lang]}</p>
                                            <div>
                                                <label>{loginTrans.email[lang]}</label>
                                                <br />
                                                <div className="d-flex mt-2">
                                                    <button className="btn btnfooter w-50 me-2" style={{ height: "50px" }}>{HomePage.subscribeNow[lang]}</button>
                                                    <input type="text" className="form-control w-75" style={{ height: "50px" }} />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </aside>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <hr style={{ color: "white" }} />

            <div className="d-flex justify-content-between align-items-center" style={{ color: "white", textAlign }}>
                <p>
                    <Link style={{ color: "white" }}>All Rights Reserved | Terms and Conditions | Privacy Policy</Link>
                </p>
                <p>Copyright © 2024 OLP </p>
            </div>
        </div>
    );
};

export default FooterWidgets;
