import { CREATE_MATERIAL,DELETE_MATERIAL,GET_MATERIALS,UPDATE_MATERIAL } from "../actions/curriculums/materials"

export const materials = (state=[],action)=>{
    switch(action.type){
        case CREATE_MATERIAL:
            return state=[...state,action.material]
        case UPDATE_MATERIAL:
            return state=state.map(s=>{
                if(s._id===action.material._id){
                    return action.material
                }
                return s
            })
        case DELETE_MATERIAL:
            return state = state.filter(s=>s._id!==action.id)
        case GET_MATERIALS:
            return state= action.materials
        default:return state
    }   
}
