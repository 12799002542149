import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { Cancel_Withdraws_Act, Cancel_Withdraws_Act_Teacher } from '../../../actions/withdrawsAct';
import { ErrorMsg } from '../../shared/modules/Notifications';


const CancelWithdraw = ({ withdraw, type }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const loggedUser = useSelector((state) => state.loggedUser);

    const handleAcceptWithdraw = async () => {
        try {
            setLoading(true);

            await dispatch(Cancel_Withdraws_Act_Teacher(withdraw?._id, loggedUser?.user?._id));
        } catch (e) {
            console.log(e);
            ErrorMsg(e?.response?.data?.message)
        } finally {
            setLoading(false);

            handleClose();
        }
    }
    return (
        <div>
            <button
                className={`btn ${type === 'accept' ? 'btn-success' :
                    type === 'cancel' ? 'btn-warning' :
                        type === 'reject' ? 'btn-danger' : 'btn-success'
                    }`}
                onClick={handleShow}
                style={{
                    minWidth: '80px',
                    fontSize: '14px',
                    fontWeight: 'bold',
                    margin: '2px'
                }}
            >
                {type === 'accept' ? 'Accept' :
                    type === 'cancel' ? 'Cancel' :
                        type === 'reject' ? 'Reject' : 'Accept'}
            </button>
            <Modal show={show} onHide={handleClose} className='font' centered>
                <Modal.Body>
                    <p>Accept This Withdraw</p>

                    <div className='mt-3'>
                        withdraw Details :
                        <p>{withdraw?.details}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-secondary' onClick={handleClose}>Close</button>
                    <button
                        className={`btn ${type === 'accept' ? 'btn-success' :
                            type === 'cancel' ? 'btn-warning' :
                                type === 'reject' ? 'btn-danger' : 'btn-success'
                            }`}
                        disabled={loading}
                        onClick={handleAcceptWithdraw}
                    >
                        {loading ? 'Loading...' :
                            type === 'accept' ? 'Accept' :
                                type === 'cancel' ? 'Cancel' :
                                    type === 'reject' ? 'Reject' : 'Accept'
                        }
                    </button>

                </Modal.Footer>
            </Modal>

        </div>
    )
}

export default CancelWithdraw
