
import { ACCEPT_WITHDRAWS, INTIALIZE_WITHDRAWS } from "../actions/withdrawsAct";

export const withdrawRed = (state = [], action) => {
    switch (action.type) {
        case INTIALIZE_WITHDRAWS:
            return state = action.withdraws
        // case CREATE_NEW_PACKAGE:
        //     return state = [...state, action.pack]
        case ACCEPT_WITHDRAWS:
            return state = state.map(s => {
                if (s._id === action.withdraw._id) {
                    return action.withdraw
                }
                return s
            })
        // case DELETE_PACKAGE:

        //     return state = state.filter(s => s._id !== action.id)
        default: return state
    }
}

