import axios from 'axios';
import { urlBase } from './API';

export const getAllTransactions = (userId, page = 1, limit = 5, filters = {}) => {
    const params = {
        page: page,
        limit: limit,
    };
    if (filters.status) {
        params.status = filters.status;
    }
    if (filters.type) {
        params.type = filters.type;
    }
    return axios({
        method: "GET",
        // url: `${urlBase}/api/transactions?userIId=${userId}&page=${page}&limit=${limit}`,
        url: `${urlBase}/api/transactions?populate=user&userIId=${userId}`,
        params: params,
    })
}


// HAndle Deletes a transaction

export const Delete_Transaction = (id, userId) => {
    return axios({
        method: "DELETE",
        url: `${urlBase}/api/transactions/${id}?userId=${userId}`,
    })
}


// Handle Update Transaction
export const Update_Transaction = (id, userId, data) => {
    return axios({
        method: "PATCH",
        url: `${urlBase}/api/transactions/${id}?userId=${userId}`,
        data: data
    })
}


// Get Me Transactions
export const get_My_Trans = (page,limit,filters,userId)=>{
    const params = {
        page: page,
        limit: limit,
    };
    if (filters.status) {
        params.status = filters.status;
    }
    if (filters.type) {
        params.type = filters.type;
    }
    return axios({
        method: "GET",
        url:`${urlBase}/api/users/me/transactions?userIId=${userId}`,
        params:params
    })
}
