import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Get_My_Withdraw_Teacher } from '../../../actions/withdrawsAct';
import { Table } from 'react-bootstrap';
import AcceptDraw from '../../../components-old/Admin/Withdraws/AcceptDraw';
import CreateWithdraw from './CreateWithdraw';
import CancelWithdraw from './CancelWithdraw';

const WithdrawTeacher = () => {
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);
    useEffect(() => {
        const getWithdraws = async () => {
            dispatch(Get_My_Withdraw_Teacher(loggedUser?.user?._id))
        }
        getWithdraws()
    }, [])

    const withdrawsTecher = useSelector((state) => state.withdrawTecRed);


    return (
        <div>
            <div className="mb-5">
                <CreateWithdraw />
            </div>
            <Table striped bordered hover responsive className='font'>
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Currency</th>
                        <th>Status</th>
                        <th>Details</th>
                        <th>User</th>
                        {/* <th>Accept</th>
                        <th>Reject</th> */}
                        <th>Cancel</th>
                    </tr>
                </thead>
                <tbody>
                    {withdrawsTecher?.map((withdraw) => (
                        <tr key={withdraw?._id}>
                            <td>{new Date(withdraw?.date).toLocaleDateString()}</td>
                            <td>{withdraw?.amount}</td>
                            <td>{withdraw?.currency}</td>
                            <td>
                                <span className={`badge ${withdraw?.status === 'accepted' ? 'bg-success' :
                                    withdraw?.status === 'canceled' ? 'bg-danger' :
                                        withdraw?.status === 'processing' ? 'bg-warning' : 'bg-success'
                                    }`}>
                                    {withdraw?.status}
                                </span>
                            </td>
                            <td>{withdraw?.details}</td>
                            <td>{withdraw?.user?.name}</td>
                            {/* <td>
                                <AcceptDraw withdraw={withdraw} type="accept" />
                            </td>
                            <td>
                                <AcceptDraw withdraw={withdraw} type="reject" />
                            </td> */}
                            <td>
                                <CancelWithdraw withdraw={withdraw} type="cancel" />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    )
}

export default WithdrawTeacher
