import axios from "axios";
import { urlBase } from "./API";

export const getWithdraws = (userId) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/withdraws?userIId=${userId}&populate=user`,
    });
};


export const acceptWithdraw = (id,userId)=>{
    return axios({
        method:"PATCH",
        url:`${urlBase}/api/withdraws/${id}/accept?userIId=${userId}`
    })
}
export const cancleWithdraw = (id,userId)=>{
    return axios({
        method:"PATCH",
        url:`${urlBase}/api/withdraws/${id}/cancel?userIId=${userId}`
    })
}
export const rejectWithdraw = (data,id,userId)=>{
    return axios({
        method:"PATCH",
        url:`${urlBase}/api/withdraws/${id}/reject?userIId=${userId}`,
        data:data
    })
}

// Handle Get My Withdraw Teacher
export const getMyWithdraws = (userId) => {
    return axios({
        method: "GET",
        url: `${urlBase}/api/users/me/withdraws?userIId=${userId}&populate=user`,
    });
};


// Create Withdraw
export const createWithdraw = (data,userId)=>{
    return axios({
        method:"POST",
        url:`${urlBase}/api/users/me/withdraws?userIId=${userId}`,
        data:data,
    })
}