import React, { useContext, useRef, useState } from "react";
import "../loginPage/styles/loginPag.css";
import { MdEmail } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import { logIn, Register_Teacher } from "../../../actions/login&register";
import { FaFacebookF } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import google from "../../../assets/google.png";
import login_Rectangle from "../../../assets/teacherLogin.png";
import { loginTrans } from "../../../utils/Translation/loginAndRegister";
import PageContainer from "../../layout/PagecContainer";
import { ThemeContext } from "../../../ThemeProvider";
import { HomePage } from "../../../utils/Translation/HomePage";
import serializeForm from "form-serialize";
import { toast } from "react-toastify";
import AuthGoogle from "../AuthGoogle";
import ModalBookPackage from "./ModalBookPackage";
import { checkCompleteRegisterTeacher } from "../../../utils/API";
import { ErrorMsg, success } from "../../shared/modules/Notifications";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const RegisterTeacher = () => {
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const { lang } = useSelector(({ language }) => ({
        lang: language.lang,
    }));
    const [phone, setPhone] = useState(null);
    const form = useRef();
    const dispatch = useDispatch();
    const { theme } = useContext(ThemeContext);

    const navigate = useNavigate();

    const handlePhone = (valuePhone) => {
        setPhone(valuePhone)
    }

    const createNew = async (e) => {
        try {
            e.preventDefault();
            setLoading(true); // Start loading
            const data = serializeForm(form.current, { hash: true });
            // console.log(data);
            if (!data.name) {
                return toast.error(`"name" is required!`);
            }
            if (!data.email) {
                return toast.error(`"email" is required!`);
            }
            if (!data.password) {
                return toast.error(`"password" is required!`);
            }
            // if (!data.phone) {
            //     return toast.error(`"phone" is required!`);
            // }
            data.phone = `+${phone}`
            // data.role = "teacher";
            const today = new Date();
            data.date = today.toISOString();
            const res = await dispatch(Register_Teacher({
                teacher: data
            }));

            // console.log("Register", res)

            if (res?.data?.case) {
                success(` مرحبا يا ${data?.name}تم تسجيل حسابك كمعلم `)
                let formData = {
                    email: data.email,
                    password: data.password,
                };
                const resLogin = await dispatch(logIn(formData));
                // console.log(resLogin)
                // toast.success(res?.data?.message);
                // navigate(`/`);

                let id = resLogin.user?._id;
                const name = resLogin.user?.name;
                // هنا يتم استدعاء checkComplete قبل التنقل
                const checkComplete = await checkCompleteRegisterTeacher(id);
                if (checkComplete?.data?.case) {
                    // إذا كانت النتيجة true، انتقل إلى الصفحة
                    navigate(`/dashboard/${name.split(" ").join("")}/${id}/profile`);
                    
                    // setShowModal(true);
                } else {
                    navigate(`/classroom`);
                }

                // navigate(`/dashboard/${name.split(" ").join("")}/${id}/profile`);
                // setShowModal(true);
            }

        } catch (e) {
            console.log(e?.status)
            if (e?.status===400){
                navigate(`/classroom`);
                success('قم باكمال حسابك الان')
            }
            else{

                ErrorMsg(e?.response?.data?.message);
            }
        } finally {
            setLoading(false); // End loading
        }
    };



    const closeModal = () => setShowModal(false);
    return (
        <div>
            <PageContainer>
                <div className="loginpage font">
                    <div className="container spacePage">
                        {!loading ? (
                            <div className="loginForm py-4">
                                {message && (
                                    <div
                                        className={`mt-3 text-center alert ${message?.auth ? "alert-success" : "alert-danger"
                                            }`}
                                        role="alert"
                                    >
                                        {message?.message}
                                    </div>
                                )}
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="formPart">
                                            <form ref={form} onSubmit={createNew} className="w-100">
                                                <div className="font text-center">
                                                    <p className="color">
                                                        {HomePage.welcomeSentence[lang]}
                                                    </p>
                                                    <p
                                                        style={{
                                                            color: `${theme === "light" ? "black" : "black"}`,
                                                        }}
                                                    >
                                                        {HomePage.pleaseLogin[lang]}
                                                    </p>
                                                </div>
                                                {/* Links */}
                                                <div>
                                                    <AuthGoogle type="teacher" />
                                                </div>
                                                {/* Or */}
                                                <div className="hr mt-5">
                                                    <div className="line"></div>
                                                    <div className="font">او</div>
                                                    <div className="line"></div>
                                                </div>

                                                {/* Form inputs */}
                                                <div className="mb-3 mt-4 positionInput">
                                                    <input
                                                        type="text"
                                                        name="name"
                                                        className="form-control"
                                                        placeholder={loginTrans.name[lang]}
                                                    />
                                                </div>

                                                <div className="mb-3 mt-4 positionInput">
                                                    <input
                                                        type="text"
                                                        name="username"
                                                        placeholder={loginTrans.user_name[lang]}
                                                        className="form-control"
                                                    />
                                                </div>

                                                <div className="mb-3 mt-4 positionInput">
                                                    <input
                                                        name="email"
                                                        type="email"
                                                        placeholder={loginTrans.email[lang]}
                                                        className="form-control mx-2"
                                                    />
                                                </div>

                                                <div className="mb-3 mt-4 positionInput">
                                                    <input
                                                        name="password"
                                                        type="password"
                                                        placeholder={loginTrans.pass[lang]}
                                                        className="form-control mx-2"
                                                    />
                                                </div>

                                                <div className="mb-3 mt-4 positionInput">
                                                    {/* <input
                                                        name="phone"
                                                        type="number"
                                                        placeholder={loginTrans.phone[lang]}
                                                        className='form-control mx-2'
                                                    /> */}
                                                    <PhoneInput
                                                        country={'eg'}
                                                        name="phone"
                                                        style={{ width: "100%" }}
                                                        value={phone}
                                                        onChange={(phone) => handlePhone(phone)}
                                                    />
                                                </div>

                                                {/* Additional Help for password and remember */}
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <Link className="link color font" to="/forget-pass">
                                                            {loginTrans.password[lang]}
                                                        </Link>
                                                    </div>
                                                    <div>
                                                        <label
                                                            className="me-4 font"
                                                            style={{
                                                                color: `${theme === "light" ? "black" : "black"}`,
                                                            }}
                                                        >
                                                            {loginTrans.checkbox[lang]}
                                                        </label>
                                                        <input type="checkbox" name="remember me" />
                                                    </div>
                                                </div>

                                                {/* Submit button */}
                                                <div
                                                    className="text-center"
                                                    style={{ margin: "35px auto" }}
                                                >
                                                    <input
                                                        type="submit"
                                                        className="w-100 font btn buttonsGroub"
                                                        value={loginTrans.completed[lang]}
                                                    />
                                                </div>

                                                {/* Register now */}
                                                <div className="mt-4 text-center">
                                                    <div className="font">
                                                        <span>{loginTrans.accountHasNot[lang]}</span> &nbsp;
                                                        <br />
                                                        <Link to="/register">
                                                            {loginTrans.subscripe[lang]}
                                                        </Link>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="col-md-6 d-none d-md-block">
                                        <div className="imagePart">
                                            <img
                                                src={login_Rectangle}
                                                className="login-image w-100 h-100"
                                                alt="error"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="loaderIcon">
                                <span className="loader"></span>
                            </div>
                        )}
                    </div>
                </div>
                <ModalBookPackage show={showModal} handleClose={closeModal} />
            </PageContainer>
        </div>
    );
};

export default RegisterTeacher;
