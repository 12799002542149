
import { CANCEL_WITHDRAWS, CREATE_WITHDRAW, GET_MY_WITHDRAWS } from "../actions/withdrawsAct";

export const withdrawTecRed = (state = [], action) => {
    switch (action.type) {
        case GET_MY_WITHDRAWS:
            return state = action.withdraws
        case CREATE_WITHDRAW:
            return state = [...state, action.withdraw]
        case CANCEL_WITHDRAWS:
            return state = state.map(s => {
                if (s._id === action.withdraw._id) {
                    return action.withdraw
                }
                return s
            })
        // case DELETE_PACKAGE:

        //     return state = state.filter(s => s._id !== action.id)
        default: return state
    }
}

