import {urlBase} from './API';
import axios from 'axios';
export const newPack = (data,userId)=>{
    return axios({
      method:'POST',
      data:data,//{materials , price,name,branchId,levelId}
      //materials:[{materialCode, selectedLessons}]
      withCredentials:true,
      url:`${urlBase}/api/create/package?userId=${userId}`,
    })
}

//  update package

export const updatePackage = (data) =>{
    return axios({
      method:'POST',
      url:`${urlBase}/api/update/package`,
      data:data,//{id,materials or active}
      withCredentials:true,
    })
}
// GET ALL PACKAGES
export const getAllPackages = ()=>{
    return axios({
      method:'get',
      url:`${urlBase}/api/get-all-packages`,
      responseType: "json",
    })
  }

  
  //GET PACKAGE BY ID
  export const getPackageById = (id)=>{
    return axios({
      method:'POST',
      url:`${urlBase}/api/get/package/by/id`,
      data:id,
      withCredentials:true
    })
  }
  // DELETE PACKAGE 
  export const deletePackage = (id,userId) =>{
    return axios({
      method:"delete",
      url:`${urlBase}/api/delete/package?userId=${userId}`,
      data:{id},
      withCredentials:true
    })
  }
