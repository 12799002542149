import axios from "axios";
// export const urlBase = 'http://localhost:8082';
// export const urlBase = ''
export const urlBase = 'https://olp.osbash.com'
// export const urlBase = 'https://olp-06tc.onrender.com'
// export const urlBase = 'https://bardiaat.com';
// export const urlBase = 'https://olp-v2.onrender.com'
// export const urlBase = 'http://5.182.18.247:8083'

//---- get Adm----
export const getAccounts = () => {
  return axios({
    method: "get",
    url: `${urlBase}/api/admins`,
    responseType: "json",
  });
};

//--------Register Student  Parent Accounts 
export const registerStudentParent = (data) => {
  return axios({
    method: "POST",
    url: `${urlBase}/api/register/student-parent`,
    data: data // {student,parent}
  })
}

// ----------Register--------
export const register = (data) => {
  // const url = `${urlBase}/api/register/user/${data.role}`;
  const url = `${urlBase}/api/register/user`;
  return axios({
    method: "POST",
    data: data,
    withCredentials: true,
    url: url,
  });
};

// Check username ,phone ,email

export const checkUserDataValidity = (data) => {
  return axios({
    method: "POST",
    data: data,
    url: `${urlBase}/api/check/register/user/data`
  })
}

// Reset Password  request
export const ResetPassRequest = (email) => {
  return axios({
    method: "POST",
    data: email,
    withCredentials: true,
    url: `${urlBase}/api/student/password/reset`
  })
}


// Verify Reset Code
export const verifyCodeRequest = (code) => {
  return axios({
    method: "POST",
    data: code,
    withCredentials: true,
    url: `${urlBase}/api/student/verify/code`
  })
}

//--------check login statue when reload or new session-----------
export const intializeUser = () => {
  return fetch(`${urlBase}/api/auth`, {
    credentials: "include",
  })
    .then((res) => res.json())
    .then((user) => user)
    .catch((err) => {
      console.log(err);
    });
};

//------- logOut --------

export const logOut = () => {
  return axios({
    method: "get",
    url: `${urlBase}/api/logout`,
    withCredentials: true
  })
}

// --------  login------
export const getAuthedUser = async (authentcation) => {

  return axios({
    method: "POST",
    data: {
      username: authentcation.phone || authentcation.email,
      phone: typeof parseInt(authentcation.phone) === Number ? true : false,
      password: authentcation.password,
      device: authentcation.device
    },
    withCredentials: true,
    url: `${urlBase}/api/login`,
  }).then((res) => res.data);
};
//

//GET USER BY ID {userID: }
export const getUserByID = (userID) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/get-user-data-by-id`,
    data: userID,
    withCredentials: true
  })
}


export const getUsersByIDS = (IDS) => {
  return axios({
    method: "POST",
    url: `${urlBase}/api/get-users-by-ids`,
    data: IDS//{IDS:IDS}

  })
}
//----- uploading files------

export const uploading = (files) => {
  return axios({
    method: "POST",
    data: files,
    withCredentials: true,
    url: `${urlBase}/api/upload`,
  });
};


//------- Users end point-----
export const getTeachers = (role) => {
  console.log(role)
  return axios({
    method: "get",
    url: `${urlBase}/api/getusers/${role}`,
  });
};
//-------- set schedual-------
export const setSchedual = (data) => {
  return axios({
    method: "POST",
    data: data,
    withCredentials: true,
    url: `${urlBase}/api/teacher/schedual`,
  });
};

export const update_User = (user) => {
  return axios({
    method: "POST",
    data: user,
    withCredentials: true,
    url: `${urlBase}/api/user/update`,
  });
};

export const delTeacher = (id) => {
  return axios({
    method: "POST",
    data: { id: id },
    withCredentials: true,
    url: `${urlBase}/api/user/delete`,
  });
};

//-------- Students end points-----

export const getStudents = () => {
  return axios({
    method: "get",
    url: `${urlBase}/api/getusers/student`,
    responseType: "json",
  });
};

export const delStudent = (id) => {
  return axios({
    method: "POST",
    data: { id: id },
    withCredentials: true,
    url: `${urlBase}/api/user/delete`,
  });
};

// send a message
export const sendMessage = (data) => {
  //{message:{title:String,content:String},to:'',sender:''}
  return axios({
    method: "POST",
    data: data, //
    withCredentials: true,
    url: `${urlBase}/api/${data.type}/send`, // type: student or teacher
  });
};
// ----------Translation ---------------

export const saveTranslation = (data) => {
  return axios({
    method: 'post',
    url: `${urlBase}/api/save/translation`,
    data: data//==> {lang:'',text object}
  })
}
export const getTranslation = async () => {

  return axios({
    method: 'get',
    url: `${urlBase}/api/get/translation`,
  }).then(res => {
    return res.data
  })
}

//  levels 
// 1- delete
export const delete_Level = (id) => {
  return axios({
    method: 'delete',
    url: `${urlBase}/api/delete-level`,
    data: id,
    withCredentials: true
  })
}

//2- create

export const create_Levele = (data) => {
  return axios({
    method: 'post',
    url: `${urlBase}/api/new-level`,
    data: data,
    withCredentials: true
  })
}

//  get All levels

export const get_All_Levels = () => {
  return axios({
    method: 'get',
    url: `${urlBase}/api/get-levels`,
    withCredentials: true
  })
}
// update level

export const updateLevel = (level) => {
  console.log(level)
  return axios({
    method: 'post',
    url: `${urlBase}/api/update-level`,
    data: level,
    withCredentials: true,

  })
}
// GET LEVEL BY ID
export const getLevelById = (id) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/get/level/by/id`,
    data: id,
    withCredentials: true
  })
}

//verify password
// {id,password}

export const verifyPassword = (data) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/pass/verify`,
    data: data,
    withCredentials: true
  })
}

//update user info  ​
//{name,username,email,phone,id}
export const updateUserInfo = (data) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/update/user/personal/data`,
    data: data,
    withCredentials: true
  });
}

//change user password
//{id , password}
export const changeUserPassword = (data) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/change-my-pass`,
    data: data,
    withCredentials: true
  });
}

// get Admins
export const getAdmins = (data) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/change-my-pass`,
    data: data,
    withCredentials: true
  });
}


// Update Teacher ​​Request :logo,photo,about,brandName,userId
export const updateTeacher = (data) => {
  console.log(data)
  return axios({
    method: 'POST',
    url: `${urlBase}/api/add/user/public/contents`,
    data: data,
    withCredentials: true
  });
}


export const uploadFileLocaly = (data) => {
  console.log(data)
  return axios({
    method: 'POST',
    url: `${urlBase}/api/upload-file-locally?userId=${data?.user}`,
    data: data,
    withCredentials: true
  });
}


// Check Complete Register Teacher
export const checkCompleteRegisterTeacher = (userId) => {
  return axios({
    method: 'GET',
    url: `${urlBase}/api/users/me/registeration-completness?userId=${userId}`,
    withCredentials: true
  });
}


// Teacher Complete Register
export const teacherCompleteRegister = (data, userId) => {
  return axios({
    method: 'POST',
    url: `${urlBase}/api/users/teacher/request-materials?userId=${userId}`,
    data: data,
    withCredentials: true
  });
}


// Api For check type Validation
export const checkTypevalidation = (userId) => {
  return axios({
    method: "GET",
    url: `${urlBase}/api/users/has-valid-subscription?type=STUDENT&userId=${userId}`
  })
}
export const checkTypevalidationStorage = (userId) => {
  return axios({
    method: "GET",
    url: `${urlBase}/api/users/has-valid-subscription?type=STORAGE&userId=${userId}`
  })
}

// Api For Renew Subscriptions (Teacher)

export const renewSubscriptions = (data, id, userId) => {
  return axios({
    method: "PATCH",
    url: `${urlBase}/api/packages-subscriptions/${id}/renew?userId=${userId}`,
    data: data
  })
}


// Get New Teachers
export const getNewTeachers = (userId) => {
  return axios({
    method: "GET",
    url: `${urlBase}/api/users/users?teacherRequest[exists]=true&role=teacher?userId=${userId}`
  })
}


// Teacher Request Main Material
export const teacherRequestMainMaterial = (data, userId) => {
  return axios({
    method: "POST",
    url: `${urlBase}/api/users/teacher/request-materials?userId=${userId}`,
    data: data
  })
}