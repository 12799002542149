import React from 'react'
import PageContainer from '../layout/PagecContainer'
import { useSelector } from 'react-redux'
import { HomePage } from '../../utils/Translation/HomePage'

const Privacy = () => {
    const sectionStyle = {
        marginBottom: '2rem'
    }

    const titleStyle = {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#007bff',
        marginBottom: '1rem',
        // borderBottom: '2px solid #007bff',
        paddingBottom: '0.5rem'
    }

    const contentStyle = {
        fontSize: '1rem',
        lineHeight: '1.6',
        color: '#666'
    }

    const listStyle = {
        paddingRight: '20px',

    }

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <PageContainer>
            <div className='container py-5 font' style={{ marginTop: "80px" }} dir={dir}>
                <h1 style={{ ...titleStyle, fontSize: '2rem', textAlign: 'center', marginBottom: '2rem' }}>
                    {HomePage.privacy_title[lang]}
                </h1>

                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.privacy_intro_title[lang]}</h2>
                    <div style={contentStyle}>
                        {HomePage.privacy_intro_content[lang]}
                    </div>
                </section>

                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.data_collection_title[lang]}</h2>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>
                                {HomePage.registration_data[lang]}
                            </li>
                            <li>{HomePage.payment_data[lang]}</li>
                            <li>
                                {HomePage.payment_data[lang]}
                            </li>
                            <li>
                                {HomePage.cookies_data[lang]}
                            </li>
                        </ul>
                    </div>
                </section>

                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.data_collection_reasons[lang]}</h2>
                    <div style={contentStyle}>
                        <h3>{HomePage.services_provision[lang]}:</h3>
                        <ul style={listStyle}>
                            <li>{HomePage.account_management[lang]}</li>
                            <li>{HomePage.payment_processing[lang]}</li>
                            <li>{HomePage.technical_support[lang]}</li>
                        </ul>
                        <h3>{HomePage.service_improvement[lang]}:</h3>
                        <ul style={listStyle}>
                            <li>{HomePage.data_analysis[lang]}</li>
                            <li>{HomePage.user_experience[lang]}</li>
                        </ul>
                        <h3>{HomePage.legal_compliance[lang]}:</h3>
                        <ul style={listStyle}>
                            <li>{HomePage.payment_records[lang]}</li>
                        </ul>
                    </div>
                </section>



                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.data_protection[lang]}</h2>
                    <div style={contentStyle}>
                        <p>{HomePage.security_measures[lang]}:</p>
                        <ul style={listStyle}>
                            <li>{HomePage.encryption[lang]}</li>
                            <li>{HomePage.firewalls[lang]}</li>
                            <li>{HomePage.system_monitoring[lang]}</li>
                        </ul>
                    </div>
                </section>

                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.data_sharing[lang]}</h2>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>{HomePage.payment_providers[lang]}</li>
                            <li>{HomePage.authorities[lang]}</li>
                        </ul>
                    </div>
                </section>


                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.user_rights[lang]}</h2>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>1 {HomePage.data_access[lang]}.</li>
                            <li>2 {HomePage.data_correction[lang]}.</li>
                            <li>3 {HomePage.data_deletion[lang]}.</li>
                            <li>4 {HomePage.objection[lang]}.</li>
                        </ul>
                    </div>
                </section>

                {/* // <section style={sectionStyle}>
                //     <h2 style={titleStyle}>ملفات تعريف االرتباط (Cookies)
                //     </h2>
                //     <div style={contentStyle}>
                //         تستخدم المنصة ملفات تعريف االرتباط لتحسين تجربتك. يمكنك إدارة تفضيالت ملفات تعريف االرتباط من خالل إعدادات
                //         المتصفح الخاص بك.
                //     </div>
                // </section> */}


                <h1 style={{ ...titleStyle, fontSize: '2rem', textAlign: 'center', marginBottom: '2rem' }}>
                    {HomePage.terms_of_use[lang]}
                </h1>

                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.registration[lang]} </h2>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>•{HomePage.account_requirement[lang]}.</li>
                            <li>•{HomePage.accurate_data[lang]}.</li>
                        </ul>
                    </div>
                </section>
                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.services_provided[lang]}
                    </h2>

                    <p>{HomePage.olp_services[lang]}
                    </p>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>1{HomePage.educational_services[lang]}.</li>
                            <li>2 {HomePage.storage_spaces[lang]}.</li>
                            <li>3{HomePage.video_conferences[lang]}.</li>
                        </ul>
                    </div>
                </section>



                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.teacher_services[lang]}</h2>
                    <div style={contentStyle}>
                        <p>{HomePage.teacher_subscription_info[lang]}:</p>
                        <ul style={listStyle}>
                            <li>{HomePage.student_limit_service[lang]}</li>
                            <li>{HomePage.storage_service[lang]}</li>
                        </ul>
                    </div>
                </section>

                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.student_services[lang]}</h2>
                    <div style={contentStyle}>
                        •{HomePage.student_benefits[lang]}.
                    </div>
                </section>

                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.payment[lang]}</h2>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>•{HomePage.subscription_fees[lang]}.</li>
                            <li>•{HomePage.payment_schedule[lang]}.</li>
                        </ul>
                    </div>
                </section>
                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.refund[lang]}
                    </h2>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>•{HomePage.user_responsibility[lang]}.
                            </li>
                            <li>{HomePage.platform_protection[lang]}</li>
                        </ul>
                    </div>
                </section>
                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.responsibility[lang]}
                    </h2>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>•{HomePage.refund_policy[lang]}.</li>
                        </ul>
                    </div>
                </section>
                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.service_termination[lang]}</h2>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>•{HomePage.cancellation_right[lang]}.</li>
                            <li>•{HomePage.account_suspension[lang]}.</li>
                        </ul>
                    </div>
                </section>


                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.acceptable_use[lang]}
                    </h2>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>•{HomePage.prohibited_behavior[lang]}: </li>
                            <li>o{HomePage.inappropriate_content[lang]}.</li>
                            <li>o{HomePage.hate_promotion[lang]}.</li>
                            <li>o{HomePage.intellectual_property_violation[lang]}.</li>
                        </ul>
                    </div>
                </section>


                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.intellectual_property[lang]}</h2>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>{HomePage.content_ownership[lang]}</li>
                            <li>{HomePage.content_protection[lang]}</li>
                        </ul>
                    </div>
                </section>
                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.disclaimer[lang]}
                    </h2>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>•{HomePage.service_provision[lang]}.
                            </li>
                            <li>
                                •{HomePage.liability_limitation[lang]}.

                            </li>
                        </ul>
                    </div>
                </section>
                <section style={sectionStyle}>
                    <h2 style={titleStyle}>{HomePage.dispute_resolution[lang]}

                    </h2>
                    <div style={contentStyle}>
                        <ul style={listStyle}>
                            <li>•{HomePage.dispute_resolution[lang]}.
                            </li>
                            <li>•{HomePage.legal_jurisdiction[lang]}.
                            </li>
                        </ul>
                    </div>
                </section>

                <section style={sectionStyle}>

                    <div style={contentStyle}>
                        <p>{HomePage.contact_info[lang]}:</p>
                        <p>{HomePage.emailAccess[lang]}</p>
                        <p>{HomePage.phoneAccess[lang]}</p>
                    </div>
                </section>
            </div>
        </PageContainer>
    )
}

export default Privacy
