import React, { useContext } from 'react'
import "./timeline.css"
import { ThemeContext } from '../../ThemeProvider';
import { useSelector } from 'react-redux';
import { HomePage } from '../../utils/Translation/HomePage';

const Timeline = ({ activeStep }) => {
    const { lang, dir } = useSelector(({ language }) => ({
        lang: language.lang,
        dir: language.dir,
    }));
    const { theme } = useContext(ThemeContext)
    const steps = [
        { id: 1, label: `${HomePage.selectCategory[lang]}`, isActive: activeStep >= 1 },
        { id: 2, label: `${HomePage.selectStage[lang]}`, isActive: activeStep >= 2 },
        { id: 3, label: `${HomePage.selectGrade[lang]}`, isActive: activeStep >= 3 },
        { id: 4, label: `${HomePage.selectSubject[lang]}`, isActive: activeStep >= 4 },
        { id: 5, label: `${HomePage.selectChapters[lang]}`, isActive: activeStep >= 5 },
        { id: 6, label: `${HomePage.paymentData[lang]}`, isActive: activeStep >= 6 },
        { id: 7, label: `${HomePage.donePay[lang]}`, isActive: activeStep >= 7 }
    ];

    return (
        <div>
            <div className="timeline font">
                {steps.map((step) => (
                    <div key={step.id} className={`step ${step.isActive ? 'active' : ''}`}>
                        <div className="circle">{step.id}</div>
                        <div className="label" style={{ color: theme === "light" ? "" : "white" }}>{step.label}</div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Timeline
