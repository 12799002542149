import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { ErrorMsg, success } from '../../shared/modules/Notifications';
import { useDispatch, useSelector } from 'react-redux';
import { Create_Withdraw_Teacher } from '../../../actions/withdrawsAct';

const CreateWithdraw = () => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [amount, setAmount] = useState(0);
    const [details, setDetails] = useState('');
    const dispatch = useDispatch();
    const loggedUser = useSelector((state) => state.loggedUser);
    const [loading,setLoading] = useState(false);

    const handleCreateWithdraw = async () => {
        try {
            setLoading(true)
            const res = await dispatch(Create_Withdraw_Teacher({
                amount: amount,
                details: details,
            }, loggedUser?.user?._id));
            if(res?.data?.case){
                success(res?.data?.message)
                handleClose();
            }
        } catch (e) {
            ErrorMsg(e.response.data.message)
        }finally{
            setLoading(false)
        }
    }
    return (
        <div>
            <button className='btn btn-primary' onClick={handleShow}>Create Withdraw</button>
            <Modal show={show} onHide={handleClose} centered className='font'>
                <Modal.Header>
                    <Modal.Title>Create Withdraw</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <input type="number" className='form-control' placeholder='Amount' value={amount} onChange={(e) => setAmount(e.target.value)} />
                    </div>
                    <div className='mt-4'>
                        <input type="text" className='form-control' placeholder='Details For Withdraw' value={details} onChange={(e) => setDetails(e.target.value)} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-secondary' onClick={handleClose}>Close</button>
                    <button className='btn btn-primary' disabled={loading} onClick={handleCreateWithdraw}>
                        {loading ? 'Loading...' : 'Create Withdraw'}
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default CreateWithdraw
