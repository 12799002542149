import React from 'react'
import teacherCard from "../../assets/teacherRevers.png"
import { Link, useParams } from 'react-router-dom';
const CardClass = ({ key, item, selectedBranch, handleBranchClick }) => {
    const params = useParams();
    const getDayName = (id) => {
        const days = {
            1: "الاثنين",
            2: "الثلاثاء",
            3: "الأربعاء",
            4: "الخميس",
            5: "الجمعة",
            6: "السبت",
            7: "الأحد"
        };
        return days[id];
    };
    const formatTo12Hour = (time) => {
        const [hours, minutes] = time.split(':');
        const hour = parseInt(hours);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minutes} ${ampm}`;
    };
    return (
        <div>
            <div className="card font">
                <div className="card-header font">
                    <div className="discount-tag">
                        <div>
                            <img src={teacherCard} alt="Instructor" className="instructor-photo" />
                        </div>
                        <div className="instructor-info font">
                            <h4 style={{color:"#120C54"}}>{item?.teachersIDS[0]?.teacher?.name}</h4>
                            <div className="rating">
                                <span>👍 261 | 92%</span>
                            </div>
                        </div>
                    </div>
                    <div className="price-info font" >
                        {
                            item?.packID?.discountPrice && item?.packID?.discountPrice < item?.packID?.price ? (
                                <>
                                    <span className="current-price">EGP {item?.packID?.discountPrice}</span>
                                    <span className="original-price">EGP {item?.packID?.price}</span>
                                </>
                            ) : (
                                <span className="original-price">EGP {item?.packID?.price}</span>
                            )
                        }
                        <span className="price-type">Monthly</span>
                    </div>
                </div>
                <div className="card-content font">

                    <div className="schedule font">
                        <strong>Schedule:</strong>
                        {item?.schedual?.[0]?.selections &&
                            Object.entries(item.schedual[0].selections)
                                .filter(([_, value]) => value.selected)
                                .map(([_, value]) => (
                                    <div key={value.id} className="schedule-item">
                                        <span className="day">{getDayName(value.id)}</span>
                                        <span className="time">{formatTo12Hour(value.time)}</span>
                                    </div>
                                ))
                        }
                    </div>
                    <div className="seats font">
                        <strong>Remaining Seats:</strong> {item?.maxStudents-item?.studentsIDS?.length} out of {item?.maxStudents}
                    </div>
                </div>
                <div className="card-footer font">
                    <Link to={`/reservation-system/${params.gender}/${params?.stage}/${params?.classroom}/${params?.subject}/${params?.teacher}/${item?._id}`} className="preview-button enroll-btn">معاينة الفصل</Link>
                    {/* <button className="enroll-btn">Enroll for Free</button> */}
                </div>
            </div>
        </div>
    )
}

export default CardClass
