import React, { useState } from 'react';
import "../../../styles/dashboard.css";
import FormAccount from './FormAccount';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { HomePage } from '../../../utils/Translation/HomePage';

const Profile = () => {
    const [isEditing, setIsEditing] = useState(false);

    const toggleEditMode = () => {
        setIsEditing(!isEditing);
    };

    const loggedUser = useSelector((state) => state.loggedUser);

    const { lang, dir } = useSelector(({ language }) => {
        return { lang: language.lang, dir: language.dir };
    });

    return (
        <div className='profile' dir='rtl'>
            <div className='container'>
                {!isEditing ? (
                    <div className='profileDetails backShared mt-5' style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                        <p>{HomePage.aboutMe[lang]}</p>
                        <div className='listProfile' style={{ textAlign:`${dir === "rtl"? 'right':'left'}` }}>
                            <ul>
                                <li>{HomePage.phone[lang]}:{loggedUser?.user?.phone}</li>
                                <li>{HomePage.email[lang]} : {loggedUser?.user?.email}</li>
                            </ul>
                        </div>
                    </div>
                ) : null}

                <div className={`form-container ${isEditing ? 'show' : ''}`}>
                    {isEditing && <FormAccount />}
                </div>

                <div className='text-center mt-3'>
                    {!isEditing && (
                        <button className='btn background buttonOpacity' style={{ color: "white" }} onClick={toggleEditMode}>
                            {HomePage.editPersonalInfo[lang]}
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Profile;
