import React, { useContext, useEffect, useState } from 'react'
import "./timeline.css"
import PageContainer from '../layout/PagecContainer'
import Timeline from './Timeline'
import teaherDet from "../../assets/teacherRevers.png"
import { Link, useNavigate, useParams } from 'react-router-dom'
import { getSingleClass } from '../../utils/class'
import { ThemeContext } from '../../ThemeProvider'
import { urlBase } from '../../utils/API'
import { useSelector } from 'react-redux'
import { HomePage } from '../../utils/Translation/HomePage'
const CourseDetails = () => {
    const params = useParams();
    const navigate = useNavigate();
    const handleNavigate = () => {
        navigate(-1)
    }

    const [singleClass, setSingleClass] = useState([]);

    console.log(singleClass)
    const {theme} = useContext(ThemeContext);

    const handleGetSingleClass = async () => {
        try {
            const res = await getSingleClass(params?.idteacher)
            if (res?.data?.case) {
                setSingleClass(res?.data?.classes);
            }
        }
        catch (err) {
            console.log(err)
        }
    }


    const { lang, dir } = useSelector(({ language }) => ({
        lang: language.lang,
        dir: language.dir,
    }));

    useEffect(() => {
        handleGetSingleClass()
    }, [params?.idteacher])

    // Add this helper function at the top of your component
    const getDayName = (id) => {
        const days = {
            1: "الاثنين",
            2: "الثلاثاء",
            3: "الأربعاء",
            4: "الخميس",
            5: "الجمعة",
            6: "السبت",
            7: "الأحد"
        };
        return days[id];
    };
    const formatTo12Hour = (time) => {
        const [hours, minutes] = time.split(':');
        const hour = parseInt(hours);
        const ampm = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minutes} ${ampm}`;
    };
    return (
        <div>
            <PageContainer>
                <div style={{ paddingTop: "120px" }}>
                    <Timeline activeStep={5} />
                    {
                        singleClass?.map((item) => {
                            return (
                                <div className="course-page font" key={item?._id}>
                                    <div className="course-details">
                                        <h2 className="course-title">{item?.name}</h2>
                                        <p className="lessons-count">
                                            السعر:{item?.packID?.price}
                                        </p>
                                        <p className="lessons-count">
                                            السعر بعد الخصم:{item?.packID?.discountPrice}
                                        </p>
                                        <h3 className="section-title">وصف الكورس:</h3>
                                        <p className="course-description">
                                            {item?.packID?.description}
                                        </p>
                                        <div className="lesson-overview">
                                            <h3 className="section-title">محتويات الدرس</h3>
                                            <ul className="lessons-list">
                                                <li>
                                                    <i className="icon play-icon">▶️</i> مقدمة الدرس
                                                </li>
                                                <li>
                                                    <i className="icon lock-icon">🔒</i> الفصل الأول
                                                </li>
                                                <li>
                                                    <i className="icon lock-icon">🔒</i> الفصل الثاني
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="lesson-overview">
                                            <div className="schedule-section">
                                                <h3 className="section-title">مواعيد الحصص</h3>
                                                <div className="schedule-list">
                                                    {item?.schedual?.[0]?.selections &&
                                                        Object.entries(item.schedual[0].selections)
                                                            .filter(([_, value]) => value.selected)
                                                            .map(([_, value]) => (
                                                                <div key={value.id} className="schedule-item">
                                                                    <span className="day">{getDayName(value.id)}</span>
                                                                    <span className="time">{formatTo12Hour(value.time)}</span>
                                                                </div>
                                                            ))
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="teacher-info">
                                        <img
                                            src={`${urlBase}/${item?.teachersIDS[0]?.teacher?.photo}`} 
                                            alt="Teacher"
                                            className="teacher-image w-75"
                                        />
                                        <div className="teacher-details">
                                            <p className="teacher-name">اسم المعلم : {item?.teachersIDS[0]?.teacher?.name}</p>
                                            <p className="teacher-description">
                                                {item?.teachersIDS[0]?.teacher?.about}
                                            </p>
                                            <div className="rating">
                                                <div className="stars">
                                                    {Array.from({ length: 5 }, (_, index) => (
                                                        <span key={index} className="star filled">★</span>
                                                    ))}
                                                </div>
                                                <p className="student-count">(350 طالب)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                    <div className='m-auto text-center mt-4 mb-5 font'>
                        <button onClick={handleNavigate} className='btn w-25 mx-2 prev'   style={{ color:theme ==="light" ? "#071D5B":"white",}}>العوده للكورسات</button>
                        <Link to={`/reservation-system/male/${params?.stage}/${params?.classroom}/${params?.subject}/${params?.teacher}/${singleClass[0]?._id}/pay`}
                            state={{ classData: singleClass[0] }}
                            className='btn w-25' style={{ background: "#071D5B", color: "white" }}>{HomePage.paymentData[lang]}</Link>

                    </div>
                </div>
            </PageContainer>
        </div>
    )
}

export default CourseDetails
